import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-camera-timer',
  templateUrl: './camera-timer.component.html',
  styleUrls: ['./camera-timer.component.less']
})
export class CameraTimerComponent implements OnInit {
  @Output() lastStep: EventEmitter<boolean> = new EventEmitter();
  @Output() timerEnded: EventEmitter<boolean> = new EventEmitter();
  @Input() isVertical = false;

  timer: ReturnType<typeof setInterval>;

  constructor(private renderer: Renderer2) {}

  ngOnInit() {
      this.startTimer();
  }

  ngOnDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  public startTimer(): void {
    let count = 4;
    this.timer = setInterval(() => {
      switch (count) {
        case 2:
          this.renderer.addClass(this.renderer.selectRootElement('.countOne', true), 'highlight');
          break;
        case 3:
          this.renderer.addClass(this.renderer.selectRootElement('.countTwo', true), 'highlight');
          break;
        case 4:
          this.renderer.addClass(this.renderer.selectRootElement('.countThree', true), 'highlight');
          break;
        case 1:
          this.lastStep.emit(true);
          this.renderer.addClass(this.renderer.selectRootElement('.countCam', true), 'highlight');
          break;
        case 0:
          clearInterval(this.timer);
          this.timerEnded.emit(true);
          break;
        default:
          break;
      }

      count--;
    }, 800);
  }
}
