import { ThalesDocumentReaderApiService } from "@data/services/document-readers/thales-document-reader-api.service";
import { LogService } from "../log.service";
import { ThalesDocumentReaderApiStateService } from "./thales-doc-reader-api-state.service";
import { ThalesDocumentReaderConnectedStateService } from "./thales-doc-reader-connected-state.service";
import { WinBridgeService } from "@core/win-bridge/win-bridge.service";
import { DocumentReaderStateService } from "./document-reader-state.service";
import { Injectable } from "@angular/core";
import { isHostedInWin } from "@core/win-bridge/win.utils";
import { ApplicationStateService } from "../application-state.service";
import { take } from "rxjs";
import { WindowRef } from "../window-ref.service";
@Injectable({
  providedIn: 'any',
  useFactory: documentReaderStateFactory // Factory function name
})
export class DocumentReaderStateFactory {

  readerIsConnected = false;

  constructor(
    private logService: LogService, 
    private winBridgeService: WinBridgeService,
    private windowRef: WindowRef,
    private appStateService: ApplicationStateService
  ) {
    appStateService.isScannerConnected$.pipe(take(1)).subscribe(isConnected => {
      this.readerIsConnected = isConnected;
    });
  }

  create(): DocumentReaderStateService {
    if (this.readerIsConnected && isHostedInWin()) {
      return new ThalesDocumentReaderConnectedStateService(
        this.winBridgeService, this.logService
      );
    } else {
      return new ThalesDocumentReaderApiStateService(
        new ThalesDocumentReaderApiService(this.windowRef, this.appStateService, this.logService),
        this.logService
      );
    }
  }
}

export function documentReaderStateFactory(factory: DocumentReaderStateFactory): DocumentReaderStateService {
  return factory.create();
}






