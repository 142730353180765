import { DocumentReaderConnectionEvent } from './document-reader-connection-events.enum';
export class DocumentReaderDataMessage {
  't': DocumentReaderConnectionEvent;
  'i': number;
  'd': any;
  constructor(documentReaderConnectionEvent: DocumentReaderConnectionEvent, id: number, d?: any) {
    this.t = documentReaderConnectionEvent;
    this.i = id;
    this.d = d;
  }
}
