/* eslint-disable no-async-promise-executor */

import { BehaviorSubject, Observable, Subject, lastValueFrom, take, takeUntil, timer } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { ErrorMessage } from '@data/models/document-reader/lib/errorMessage';
import { LogService } from '@core/services/log.service';
import { ReaderDataType } from '@data/models/document-reader/lib/ReaderDataType';
import { ScannedData } from '@core/win-bridge/scanned-data.model';
import { EIdentityDocumentType } from '@core/enums/identity-document-type.enum';

@Injectable({
  providedIn: null
})
export abstract class DocumentReaderStateService implements OnDestroy {
  destroy$ = new Subject<void>();
  isInitializing$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  isProcessing$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isCompleted$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isConnecting$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isTimedOut$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  errors$: BehaviorSubject<ErrorMessage[]> = new BehaviorSubject([]);
  scannedData$: BehaviorSubject<ScannedData> = new BehaviorSubject(null);

  constructor(
    protected logService: LogService
  ) {}

  async ngOnDestroy(): Promise<void> {
    this.logService.debug(`[DocumentReaderDataState]ngOnDestroy()`);
    this.destroy$.next();
    this.destroy$.complete();
  }

  public abstract init(expectedData: ReaderDataType[], settings): Promise<boolean>;

  public abstract capture(documentType?: EIdentityDocumentType): Promise<void>;

  public abstract stop(): Promise<boolean>;

  public abstract connect(): Promise<boolean>;

  protected startTimeoutTimer(milliseconds: number, inProgressStep$: Observable<boolean>): void {
    timer(milliseconds).pipe(takeUntil(this.destroy$)).subscribe(async (_) => {
      const isStepInProgress = await lastValueFrom(inProgressStep$.pipe(take(1)));
      if (isStepInProgress) {
        this.isTimedOut$.next(true);
      }
    });
  }
}
