// DO NOT EDIT.Generated 'MessageClass' named 'docDetectSettings' in 'WebApi.Messages' from '/home/wross/src/iSeries/hw/webapi-server/shared/definitions/class.docDetectSettings.json'

export function isDocDetectSettings(obj: any): obj is DocDetectSettings {
  return (
    'active_reader' in obj &&
    'active_reader_bottom_offset' in obj &&
    'active_reader_white_threshold' in obj &&
    'active_video' in obj &&
    'active_video_count_threshold' in obj &&
    'active_video_white_threshold' in obj &&
    'boxes_white_threshold' in obj &&
    'crop_to_laminate' in obj &&
    'dark_objects' in obj &&
    'delay' in obj &&
    'movement' in obj &&
    'search_area_multiplier' in obj &&
    'use_first_image_captured_for_locate' in obj &&
    'use_ir_for_detect' in obj &&
    true
  );
}

export interface DocDetectSettingsLike {
  /**
   * Enable/Disable using firmware document detection instead of active_video.
   */
  active_reader: boolean;
  /**
   * Configure active_reader detection bottom offset.
   */
  active_reader_bottom_offset: number;
  /**
   * Configure active_reader detection white threshold.
   */
  active_reader_white_threshold: number;
  /**
   * Enable/Disable active video document detection (default) allowing for detection of documents/phones across entire glass.
   */
  active_video: boolean;
  /**
   * Configure active_video count threshold.
   */
  active_video_count_threshold: number;
  /**
   * Configure active_video detection white threshold.
   */
  active_video_white_threshold: number;
  /**
   * Configure detection boxes white threshold.
   */
  boxes_white_threshold: number;
  /**
   * Enable/Disable cropping to edge of laminate border on some documents.
   */
  crop_to_laminate: boolean;
  /**
   * Enable/Disable dark object detection used for cell phones.
   */
  dark_objects: boolean;
  /**
   * Configure document detection delay (milliseconds).
   */
  delay: number;
  /**
   * Configure active_video movement threshold.
   */
  movement: number;
  /**
   * Configure how much area of the glass is used for active_video detection (default=0 entire glass).
   */
  search_area_multiplier: number;
  /**
   * Enable/Disable using the IR image captured for document cropping instead of the image used during detection.
   */
  use_first_image_captured_for_locate: boolean;
  /**
   * Enable/Disable using IR image settings for detection instead of IR DETECT specific settings.
   */
  use_ir_for_detect: boolean;
}

export class DocDetectSettings /*extends MessageData implements IDocDetectSettings */ {
  /**
   * Enable/Disable using firmware document detection instead of active_video.
   */
  active_reader: boolean;
  /**
   * Configure active_reader detection bottom offset.
   */
  active_reader_bottom_offset: number;
  /**
   * Configure active_reader detection white threshold.
   */
  active_reader_white_threshold: number;
  /**
   * Enable/Disable active video document detection (default) allowing for detection of documents/phones across entire glass.
   */
  active_video: boolean;
  /**
   * Configure active_video count threshold.
   */
  active_video_count_threshold: number;
  /**
   * Configure active_video detection white threshold.
   */
  active_video_white_threshold: number;
  /**
   * Configure detection boxes white threshold.
   */
  boxes_white_threshold: number;
  /**
   * Enable/Disable cropping to edge of laminate border on some documents.
   */
  crop_to_laminate: boolean;
  /**
   * Enable/Disable dark object detection used for cell phones.
   */
  dark_objects: boolean;
  /**
   * Configure document detection delay (milliseconds).
   */
  delay: number;
  /**
   * Configure active_video movement threshold.
   */
  movement: number;
  /**
   * Configure how much area of the glass is used for active_video detection (default=0 entire glass).
   */
  search_area_multiplier: number;
  /**
   * Enable/Disable using the IR image captured for document cropping instead of the image used during detection.
   */
  use_first_image_captured_for_locate: boolean;
  /**
   * Enable/Disable using IR image settings for detection instead of IR DETECT specific settings.
   */
  use_ir_for_detect: boolean;
  static parse(data: string): DocDetectSettings {
    const obj = JSON.parse(data);

    if (isDocDetectSettings(obj)) {
      return DocDetectSettings.copy(obj);
    }
    return undefined;
  }

  static copy(obj: DocDetectSettingsLike): DocDetectSettings {
    if (isDocDetectSettings(obj)) {
      let copy = new DocDetectSettings();
      copy = Object.assign(copy, obj);

      // replace refs with matching signatures with class objects
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // end for

      return copy;
    }
    return undefined;
  }
} // END DocDetectSettings
