// DO NOT EDIT.Generated 'Enum' named 'ReaderDataType' in 'WebApi.Messages' from '/home/wross/src/iSeries/hw/webapi-server/shared/definitions/enum.ReaderDataType.json'
/**
 * Enumeration reader data types
 */
export enum ReaderDataType {
  // START ENUMS
  SCDG1_CODELINE = 0,
  SCDG1_CODELINE_DATA = 1,
  SCDG2_PHOTO = 2,
  SCDG3_FINGERPRINTS = 3,
  SCDG1_VALIDATE = 4,
  SCDG2_VALIDATE = 5,
  SCDG3_VALIDATE = 6,
  SCDG4_VALIDATE = 7,
  SCDG5_VALIDATE = 8,
  SCDG6_VALIDATE = 9,
  SCDG7_VALIDATE = 10,
  SCDG8_VALIDATE = 11,
  SCDG9_VALIDATE = 12,
  SCDG10_VALIDATE = 13,
  SCDG11_VALIDATE = 14,
  SCDG12_VALIDATE = 15,
  SCDG13_VALIDATE = 16,
  SCDG14_VALIDATE = 17,
  SCDG15_VALIDATE = 18,
  SCDG16_VALIDATE = 19,
  SCSIGNEDATTRS_VALIDATE = 20,
  SCSIGNATURE_VALIDATE = 21,
  SCAIRBAUD = 22,
  SCCHIPID = 23,
  SCEF_COM_FILE = 24,
  SCEF_SOD_FILE = 25,
  SCDG1_FILE = 26,
  SCDG2_FILE = 27,
  SCDG3_FILE = 28,
  SCDG4_FILE = 29,
  SCDG5_FILE = 30,
  SCDG6_FILE = 31,
  SCDG7_FILE = 32,
  SCDG8_FILE = 33,
  SCDG9_FILE = 34,
  SCDG10_FILE = 35,
  SCDG11_FILE = 36,
  SCDG12_FILE = 37,
  SCDG13_FILE = 38,
  SCDG14_FILE = 39,
  SCDG15_FILE = 40,
  SCDG16_FILE = 41,
  SCEF_CVCA_FILE = 42,
  SCBAC_STATUS = 43,
  ACTIVE_AUTHENTICATION = 44,
  VALIDATE_DOC_SIGNER_CERT = 45,
  SCTERMINAL_AUTHENTICATION_STATUS = 46,
  SCCHIP_AUTHENTICATION_STATUS = 47,
  SCCROSSCHECK_EFCOM_EFSOD = 48,
  PASSIVE_AUTHENTICATION = 49,
  SAC_STATUS = 50,
  SCEF_CARD_ACCESS_FILE = 51,
  EFCOM_DG_MAP = 52,
  EFSOD_HASH_MAP = 53,
  DOC_SIGNER_CERT = 54,
  SCDG1_EID_DOCUMENT_TYPE = 55,
  SCDG2_EID_ISSUING_ENTITY = 56,
  SCDG3_EID_VALIDITY_PERIOD = 57,
  SCDG4_EID_GIVEN_NAMES = 58,
  SCDG5_EID_FAMILY_NAMES = 59,
  SCDG6_EID_NOM_DE_PLUME = 60,
  SCDG7_EID_ACADEMIC_TITLE = 61,
  SCDG8_EID_DATE_OF_BIRTH = 62,
  SCDG9_EID_PLACE_OF_BIRTH = 63,
  SCDG10_EID_NATIONALITY = 64,
  SCDG11_EID_SEX = 65,
  SCDG12_EID_OPTIONAL_DATA_R = 66,
  SCDG13_EID_BIRTH_NAME = 67,
  SCDG14_EID_WRITTEN_SIGNATURE = 68,
  SCDG17_EID_PLACE_OF_RESIDENCE = 69,
  SCDG18_EID_MUNICIPALITY_ID = 70,
  SCDG19_EID_RESIDENCE_PERMIT_1 = 71,
  SCDG20_EID_RESIDENCE_PERMIT_2 = 72,
  SCDG21_EID_OPTIONAL_DATA_RW = 73,
  SCDG1_VALIDATE_EID = 74,
  SCDG2_VALIDATE_EID = 75,
  SCDG3_VALIDATE_EID = 76,
  SCDG4_VALIDATE_EID = 77,
  SCDG5_VALIDATE_EID = 78,
  SCDG6_VALIDATE_EID = 79,
  SCDG7_VALIDATE_EID = 80,
  SCDG8_VALIDATE_EID = 81,
  SCDG9_VALIDATE_EID = 82,
  SCDG10_VALIDATE_EID = 83,
  SCDG11_VALIDATE_EID = 84,
  SCDG12_VALIDATE_EID = 85,
  SCDG13_VALIDATE_EID = 86,
  SCDG14_VALIDATE_EID = 87,
  SCDG15_VALIDATE_EID = 88,
  SCDG16_VALIDATE_EID = 89,
  SCDG17_VALIDATE_EID = 90,
  SCDG18_VALIDATE_EID = 91,
  SCDG19_VALIDATE_EID = 92,
  SCDG20_VALIDATE_EID = 93,
  SCDG21_VALIDATE_EID = 94,
  SCDG22_VALIDATE_EID = 95,
  SCSIGNEDATTRS_VALIDATE_CARD_SECURITY_FILE = 96,
  SCSIGNEDATTRS_VALIDATE_CHIP_SECURITY_FILE = 97,
  SCSIGNATURE_VALIDATE_CARD_SECURITY_FILE = 98,
  SCSIGNATURE_VALIDATE_CHIP_SECURITY_FILE = 99,
  SCDG1_FILE_EID = 100,
  SCDG2_FILE_EID = 101,
  SCDG3_FILE_EID = 102,
  SCDG4_FILE_EID = 103,
  SCDG5_FILE_EID = 104,
  SCDG6_FILE_EID = 105,
  SCDG7_FILE_EID = 106,
  SCDG8_FILE_EID = 107,
  SCDG9_FILE_EID = 108,
  SCDG10_FILE_EID = 109,
  SCDG11_FILE_EID = 110,
  SCDG12_FILE_EID = 111,
  SCDG13_FILE_EID = 112,
  SCDG14_FILE_EID = 113,
  SCDG15_FILE_EID = 114,
  SCDG16_FILE_EID = 115,
  SCDG17_FILE_EID = 116,
  SCDG18_FILE_EID = 117,
  SCDG19_FILE_EID = 118,
  SCDG20_FILE_EID = 119,
  SCDG21_FILE_EID = 120,
  SCDG22_FILE_EID = 121,
  VALIDATE_DOC_SIGNER_CERT_CARD_SECURITY_FILE = 122,
  VALIDATE_DOC_SIGNER_CERT_CHIP_SECURITY_FILE = 123,
  SCEF_CHIP_SECURITY_FILE = 124,
  SCEF_CARD_SECURITY_FILE = 125,
  SCDG1_FILE_EDL = 126,
  SCDG2_FILE_EDL = 127,
  SCDG3_FILE_EDL = 128,
  SCDG4_FILE_EDL = 129,
  SCDG5_FILE_EDL = 130,
  SCDG6_FILE_EDL = 131,
  SCDG7_FILE_EDL = 132,
  SCDG8_FILE_EDL = 133,
  SCDG9_FILE_EDL = 134,
  SCDG10_FILE_EDL = 135,
  SCDG11_FILE_EDL = 136,
  SCDG12_FILE_EDL = 137,
  SCDG13_FILE_EDL = 138,
  SCDG14_FILE_EDL = 139,
  SCDG1_VALIDATE_EDL = 140,
  SCDG2_VALIDATE_EDL = 141,
  SCDG3_VALIDATE_EDL = 142,
  SCDG4_VALIDATE_EDL = 143,
  SCDG5_VALIDATE_EDL = 144,
  SCDG6_VALIDATE_EDL = 145,
  SCDG7_VALIDATE_EDL = 146,
  SCDG8_VALIDATE_EDL = 147,
  SCDG9_VALIDATE_EDL = 148,
  SCDG10_VALIDATE_EDL = 149,
  SCDG11_VALIDATE_EDL = 150,
  SCDG12_VALIDATE_EDL = 151,
  SCDG13_VALIDATE_EDL = 152,
  SCDG14_VALIDATE_EDL = 153,
  SCDG1_EDL_DATA = 154,
  SCDG6_EDL_PHOTO = 155,
  SCDG7_EDL_FINGERPRINTS = 156,
  BACKEY_CORRECTION = 157,
  CODELINE = 158,
  CODELINE_DATA = 159,
  CHECKSUM = 160,
  CHECKSUMEXTENDED = 161,
  IMAGEIR = 162,
  IMAGEIRREAR = 163,
  IMAGEVIS = 164,
  IMAGEVISREAR = 165,
  IMAGEVIS_OVD1 = 166,
  IMAGEVIS_OVD2 = 167,
  IMAGEPHOTO = 168,
  IMAGEUV = 169,
  IMAGEUVREAR = 170,
  IMAGECOAXVIS = 171,
  IMAGECOAXIR = 172,
  IMAGEBARCODE = 173,
  IMAGEBARCODEREAR = 174,
  SECURITYCHECK = 175,
  BARCODE_1D_INDUSTRIAL_2_OF_5 = 176,
  BARCODE_1D_INTERLEAVED_2_OF_5 = 177,
  BARCODE_1D_IATA_2_OF_5 = 178,
  BARCODE_1D_3_OF_9 = 179,
  BARCODE_1D_128 = 180,
  BARCODE_1D_CODE_93 = 181,
  BARCODE_1D_CODABAR = 182,
  BARCODE_1D_UPC_EAN = 183,
  BARCODE_PDF417 = 184,
  BARCODE_AZTECCODE = 185,
  BARCODE_QRCODE = 186,
  BARCODE_DATAMATRIX = 187,
  AAMVA_DATA = 188,
  // END ENUMS
}
