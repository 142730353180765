// DO NOT EDIT.Generated 'MessageClass' named 'rfDataToSendSettings' in 'WebApi.Messages' from '/home/wross/src/iSeries/hw/webapi-server/shared/definitions/class.rfDataToSendSettings.json'

export function isRfDataToSendSettings(obj: any): obj is RfDataToSendSettings {
  return (
    'active_authentication' in obj &&
    'air_baud_rate' in obj &&
    'bac_status' in obj &&
    'chip_authentication_status' in obj &&
    'chip_id' in obj &&
    'crosscheck_efcom_efsod' in obj &&
    'dg10_file' in obj &&
    'dg10_file_edl' in obj &&
    'dg11_file' in obj &&
    'dg11_file_edl' in obj &&
    'dg12_file' in obj &&
    'dg12_file_edl' in obj &&
    'dg13_file' in obj &&
    'dg13_file_edl' in obj &&
    'dg14_file' in obj &&
    'dg14_file_edl' in obj &&
    'dg15_file' in obj &&
    'dg16_file' in obj &&
    'dg1_edl_data' in obj &&
    'dg1_file' in obj &&
    'dg1_file_edl' in obj &&
    'dg1_mrz_data' in obj &&
    'dg2_face_jpeg' in obj &&
    'dg2_file' in obj &&
    'dg2_file_edl' in obj &&
    'dg3_file' in obj &&
    'dg3_file_edl' in obj &&
    'dg3_fingerprints' in obj &&
    'dg4_file' in obj &&
    'dg4_file_edl' in obj &&
    'dg5_file' in obj &&
    'dg5_file_edl' in obj &&
    'dg6_edl_face_jpeg' in obj &&
    'dg6_file' in obj &&
    'dg6_file_edl' in obj &&
    'dg7_edl_fingerprints' in obj &&
    'dg7_file' in obj &&
    'dg7_file_edl' in obj &&
    'dg8_file' in obj &&
    'dg8_file_edl' in obj &&
    'dg9_file' in obj &&
    'dg9_file_edl' in obj &&
    'doc_signer_cert' in obj &&
    'ef_card_access_file' in obj &&
    'ef_card_security_file' in obj &&
    'ef_chip_security_file' in obj &&
    'ef_com_file' in obj &&
    'ef_cvca_file' in obj &&
    'ef_sod_file' in obj &&
    'efcom_dg_map' in obj &&
    'efsod_hash_map' in obj &&
    'passive_authentication' in obj &&
    'sac_status' in obj &&
    'terminal_authentication_status' in obj &&
    'validate_dgs' in obj &&
    'validate_doc_signer_cert' in obj &&
    'validate_signature' in obj &&
    'validate_signed_attrs' in obj &&
    true
  );
}

export interface RfDataToSendSettingsLike {
  /**
   * Return the Active Authentication status.
   */
  active_authentication: number;
  /**
   * Returns the over-air baud rate used for RF.
   */
  air_baud_rate: number;
  /**
   * Return BAC status.
   */
  bac_status: number;
  /**
   * Return the Chip Authentication status.
   */
  chip_authentication_status: number;
  /**
   * Returns the RF chip ID.
   */
  chip_id: number;
  /**
   * Cross-check the EF.COM and EF.SOD.
   */
  crosscheck_efcom_efsod: number;
  /**
   * Return the DG10 file.
   */
  dg10_file: number;
  /**
   * Return the eDL DG10 file.
   */
  dg10_file_edl: number;
  /**
   * Return the DG11 file.
   */
  dg11_file: number;
  /**
   * Return the eDL DG11 file.
   */
  dg11_file_edl: number;
  /**
   * Return the DG12 file.
   */
  dg12_file: number;
  /**
   * Return the eDL DG12 file.
   */
  dg12_file_edl: number;
  /**
   * Return the DG13 file.
   */
  dg13_file: number;
  /**
   * Return the eDL DG13 file.
   */
  dg13_file_edl: number;
  /**
   * Return the DG14 file.
   */
  dg14_file: number;
  /**
   * Return the eDL DG14 file.
   */
  dg14_file_edl: number;
  /**
   * Return the DG15 file.
   */
  dg15_file: number;
  /**
   * Return the DG16 file.
   */
  dg16_file: number;
  /**
   * Return the DG1 eDL data.
   */
  dg1_edl_data: number;
  /**
   * Return the DG1 file.
   */
  dg1_file: number;
  /**
   * Return the eDL DG1 file.
   */
  dg1_file_edl: number;
  /**
   * Return the ePassport DG1 MRZ Codeline Data.
   */
  dg1_mrz_data: number;
  /**
   * Return the ePassport DG2 facial image.
   */
  dg2_face_jpeg: number;
  /**
   * Return the DG2 file.
   */
  dg2_file: number;
  /**
   * Return the eDL DG2 file.
   */
  dg2_file_edl: number;
  /**
   * Return the DG3 file.
   */
  dg3_file: number;
  /**
   * Return the eDL DG3 file.
   */
  dg3_file_edl: number;
  /**
   * Return the ePassport DG3 fingerprints (requires EAC certificates for Terminal Authentication).
   */
  dg3_fingerprints: number;
  /**
   * Return the DG4 file.
   */
  dg4_file: number;
  /**
   * Return the eDL DG4 file.
   */
  dg4_file_edl: number;
  /**
   * Return the DG5 file.
   */
  dg5_file: number;
  /**
   * Return the eDL DG5 file.
   */
  dg5_file_edl: number;
  /**
   * Return the DG6 eDL fingerprints.
   */
  dg6_edl_face_jpeg: number;
  /**
   * Return the DG6 file.
   */
  dg6_file: number;
  /**
   * Return the eDL DG6 file.
   */
  dg6_file_edl: number;
  /**
   * Return the DG7 eDL fingerprints (requires EAC certificates for Terminal Authentication).
   */
  dg7_edl_fingerprints: number;
  /**
   * Return the DG7 file.
   */
  dg7_file: number;
  /**
   * Return the eDL DG7 file.
   */
  dg7_file_edl: number;
  /**
   * Return the DG8 file.
   */
  dg8_file: number;
  /**
   * Return the eDL DG8 file.
   */
  dg8_file_edl: number;
  /**
   * Return the DG9 file.
   */
  dg9_file: number;
  /**
   * Return the eDL DG9 file.
   */
  dg9_file_edl: number;
  /**
   * Return the Doc Signer Certificate from the chip.
   */
  doc_signer_cert: number;
  /**
   * Return the EF.CardAccess file.
   */
  ef_card_access_file: number;
  /**
   * Return the EF.CardSecurity file.
   */
  ef_card_security_file: number;
  /**
   * Return the EF.ChipSecurity file.
   */
  ef_chip_security_file: number;
  /**
   * Return the EF.COM file.
   */
  ef_com_file: number;
  /**
   * Return the EF.CVCA file.
   */
  ef_cvca_file: number;
  /**
   * Return the EF.SOD file.
   */
  ef_sod_file: number;
  /**
   * Return the EF.COM DG presence map.
   */
  efcom_dg_map: number;
  /**
   * Return the EF.SOD hash map.
   */
  efsod_hash_map: number;
  /**
   * Return the Passive Authentication status.
   */
  passive_authentication: number;
  /**
   * Return SAC status.
   */
  sac_status: number;
  /**
   * Return the Terminal Authentication status.
   */
  terminal_authentication_status: number;
  /**
   * Validate all selected DGs.
   */
  validate_dgs: boolean;
  /**
   * Validate the Doc Signer certificate.
   */
  validate_doc_signer_cert: number;
  /**
   * Validate signature.
   */
  validate_signature: number;
  /**
   * Validate signed attributes.
   */
  validate_signed_attrs: number;
}

export class RfDataToSendSettings /*extends MessageData implements IRfDataToSendSettings */ {
  /**
   * Return the Active Authentication status.
   */
  active_authentication: number;
  /**
   * Returns the over-air baud rate used for RF.
   */
  air_baud_rate: number;
  /**
   * Return BAC status.
   */
  bac_status: number;
  /**
   * Return the Chip Authentication status.
   */
  chip_authentication_status: number;
  /**
   * Returns the RF chip ID.
   */
  chip_id: number;
  /**
   * Cross-check the EF.COM and EF.SOD.
   */
  crosscheck_efcom_efsod: number;
  /**
   * Return the DG10 file.
   */
  dg10_file: number;
  /**
   * Return the eDL DG10 file.
   */
  dg10_file_edl: number;
  /**
   * Return the DG11 file.
   */
  dg11_file: number;
  /**
   * Return the eDL DG11 file.
   */
  dg11_file_edl: number;
  /**
   * Return the DG12 file.
   */
  dg12_file: number;
  /**
   * Return the eDL DG12 file.
   */
  dg12_file_edl: number;
  /**
   * Return the DG13 file.
   */
  dg13_file: number;
  /**
   * Return the eDL DG13 file.
   */
  dg13_file_edl: number;
  /**
   * Return the DG14 file.
   */
  dg14_file: number;
  /**
   * Return the eDL DG14 file.
   */
  dg14_file_edl: number;
  /**
   * Return the DG15 file.
   */
  dg15_file: number;
  /**
   * Return the DG16 file.
   */
  dg16_file: number;
  /**
   * Return the DG1 eDL data.
   */
  dg1_edl_data: number;
  /**
   * Return the DG1 file.
   */
  dg1_file: number;
  /**
   * Return the eDL DG1 file.
   */
  dg1_file_edl: number;
  /**
   * Return the ePassport DG1 MRZ Codeline Data.
   */
  dg1_mrz_data: number;
  /**
   * Return the ePassport DG2 facial image.
   */
  dg2_face_jpeg: number;
  /**
   * Return the DG2 file.
   */
  dg2_file: number;
  /**
   * Return the eDL DG2 file.
   */
  dg2_file_edl: number;
  /**
   * Return the DG3 file.
   */
  dg3_file: number;
  /**
   * Return the eDL DG3 file.
   */
  dg3_file_edl: number;
  /**
   * Return the ePassport DG3 fingerprints (requires EAC certificates for Terminal Authentication).
   */
  dg3_fingerprints: number;
  /**
   * Return the DG4 file.
   */
  dg4_file: number;
  /**
   * Return the eDL DG4 file.
   */
  dg4_file_edl: number;
  /**
   * Return the DG5 file.
   */
  dg5_file: number;
  /**
   * Return the eDL DG5 file.
   */
  dg5_file_edl: number;
  /**
   * Return the DG6 eDL fingerprints.
   */
  dg6_edl_face_jpeg: number;
  /**
   * Return the DG6 file.
   */
  dg6_file: number;
  /**
   * Return the eDL DG6 file.
   */
  dg6_file_edl: number;
  /**
   * Return the DG7 eDL fingerprints (requires EAC certificates for Terminal Authentication).
   */
  dg7_edl_fingerprints: number;
  /**
   * Return the DG7 file.
   */
  dg7_file: number;
  /**
   * Return the eDL DG7 file.
   */
  dg7_file_edl: number;
  /**
   * Return the DG8 file.
   */
  dg8_file: number;
  /**
   * Return the eDL DG8 file.
   */
  dg8_file_edl: number;
  /**
   * Return the DG9 file.
   */
  dg9_file: number;
  /**
   * Return the eDL DG9 file.
   */
  dg9_file_edl: number;
  /**
   * Return the Doc Signer Certificate from the chip.
   */
  doc_signer_cert: number;
  /**
   * Return the EF.CardAccess file.
   */
  ef_card_access_file: number;
  /**
   * Return the EF.CardSecurity file.
   */
  ef_card_security_file: number;
  /**
   * Return the EF.ChipSecurity file.
   */
  ef_chip_security_file: number;
  /**
   * Return the EF.COM file.
   */
  ef_com_file: number;
  /**
   * Return the EF.CVCA file.
   */
  ef_cvca_file: number;
  /**
   * Return the EF.SOD file.
   */
  ef_sod_file: number;
  /**
   * Return the EF.COM DG presence map.
   */
  efcom_dg_map: number;
  /**
   * Return the EF.SOD hash map.
   */
  efsod_hash_map: number;
  /**
   * Return the Passive Authentication status.
   */
  passive_authentication: number;
  /**
   * Return SAC status.
   */
  sac_status: number;
  /**
   * Return the Terminal Authentication status.
   */
  terminal_authentication_status: number;
  /**
   * Validate all selected DGs.
   */
  validate_dgs: boolean;
  /**
   * Validate the Doc Signer certificate.
   */
  validate_doc_signer_cert: number;
  /**
   * Validate signature.
   */
  validate_signature: number;
  /**
   * Validate signed attributes.
   */
  validate_signed_attrs: number;
  static parse(data: string): RfDataToSendSettings {
    const obj = JSON.parse(data);

    if (isRfDataToSendSettings(obj)) {
      return RfDataToSendSettings.copy(obj);
    }
    return undefined;
  }

  static copy(obj: RfDataToSendSettingsLike): RfDataToSendSettings {
    if (isRfDataToSendSettings(obj)) {
      let copy = new RfDataToSendSettings();
      copy = Object.assign(copy, obj);

      // replace refs with matching signatures with class objects
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // end for

      return copy;
    }
    return undefined;
  }
} // END RfDataToSendSettings
