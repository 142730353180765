// DO NOT EDIT.Generated 'MessageClass' named 'imgSettings' in 'WebApi.Messages' from '/home/wross/src/iSeries/hw/webapi-server/shared/definitions/class.imgSettings.json'

export function isImgSettings(obj: any): obj is ImgSettings {
  return (
    'anti_glare_ir' in obj &&
    'anti_glare_vis' in obj &&
    'barcode_threads' in obj &&
    'check_visible_ocr' in obj &&
    'enhanced_dark_object_barcode_decoding' in obj &&
    'enhanced_ocr' in obj &&
    'face_find' in obj &&
    'face_find_passport' in obj &&
    'needs_debarrel' in obj &&
    'scale_down_barcode_dpi' in obj &&
    'use_ambient_for_barcode_dark_objects' in obj &&
    'use_binned_capture' in obj &&
    'use_visible_for_barcode' in obj &&
    true
  );
}

export interface ImgSettingsLike {
  /**
   * Enable/Disable AntiGlare for the IR image
   */
  anti_glare_ir: boolean;
  /**
   * Enable/Disable AntiGlare for the Visible image.
   */
  anti_glare_vis: boolean;
  /**
   * Set the number of threads used for barcode decoding.  Default = 0 (use max number of CPU cores on machine for faster decoding)
   */
  barcode_threads: number;
  /**
   * Enable/Disable checking the visible image for MRZ OCR if not found in the IR image.
   */
  check_visible_ocr: boolean;
  /**
   * Enable/Disable enhanced decoding of barcodes on dark objects (cell phones) to handle a wider range of cell phone brightness (max dim to max bright).
   */
  enhanced_dark_object_barcode_decoding: boolean;
  /**
   * Enable/Disable enhanced OCR functionality.
   */
  enhanced_ocr: boolean;
  /**
   * Enable/Disable using face find for photo location and/or auto orientation.
   */
  face_find: boolean;
  /**
   * Enable/Disable using face find for photo location on passports.  By default, the passport photo is cropped to a fixed location for speed as orientation and location is fixed.
   */
  face_find_passport: boolean;
  /**
   * Enable/Disable debarreling images.
   */
  needs_debarrel: boolean;
  /**
   * Sets target DPI of image used for MRZ OCR on higher DPI readers.
   */
  scale_down_barcode_dpi: number;
  /**
   * Enable/Disable ambient light instead of white light for decoding barcodes on dark objects (cell phones).
   */
  use_ambient_for_barcode_dark_objects: boolean;
  /**
   * Enable/Disable using binned image for faster capture and processing on high DPI readers.
   */
  use_binned_capture: boolean;
  /**
   * Enable/Disable using visible image already captured instead of additional visible capture for barcode decoding.
   */
  use_visible_for_barcode: boolean;
}

export class ImgSettings /*extends MessageData implements IImgSettings */ {
  /**
   * Enable/Disable AntiGlare for the IR image
   */
  anti_glare_ir: boolean;
  /**
   * Enable/Disable AntiGlare for the Visible image.
   */
  anti_glare_vis: boolean;
  /**
   * Set the number of threads used for barcode decoding.  Default = 0 (use max number of CPU cores on machine for faster decoding)
   */
  barcode_threads: number;
  /**
   * Enable/Disable checking the visible image for MRZ OCR if not found in the IR image.
   */
  check_visible_ocr: boolean;
  /**
   * Enable/Disable enhanced decoding of barcodes on dark objects (cell phones) to handle a wider range of cell phone brightness (max dim to max bright).
   */
  enhanced_dark_object_barcode_decoding: boolean;
  /**
   * Enable/Disable enhanced OCR functionality.
   */
  enhanced_ocr: boolean;
  /**
   * Enable/Disable using face find for photo location and/or auto orientation.
   */
  face_find: boolean;
  /**
   * Enable/Disable using face find for photo location on passports.  By default, the passport photo is cropped to a fixed location for speed as orientation and location is fixed.
   */
  face_find_passport: boolean;
  /**
   * Enable/Disable debarreling images.
   */
  needs_debarrel: boolean;
  /**
   * Sets target DPI of image used for MRZ OCR on higher DPI readers.
   */
  scale_down_barcode_dpi: number;
  /**
   * Enable/Disable ambient light instead of white light for decoding barcodes on dark objects (cell phones).
   */
  use_ambient_for_barcode_dark_objects: boolean;
  /**
   * Enable/Disable using binned image for faster capture and processing on high DPI readers.
   */
  use_binned_capture: boolean;
  /**
   * Enable/Disable using visible image already captured instead of additional visible capture for barcode decoding.
   */
  use_visible_for_barcode: boolean;
  static parse(data: string): ImgSettings {
    const obj = JSON.parse(data);

    if (isImgSettings(obj)) {
      return ImgSettings.copy(obj);
    }
    return undefined;
  }

  static copy(obj: ImgSettingsLike): ImgSettings {
    if (isImgSettings(obj)) {
      let copy = new ImgSettings();
      copy = Object.assign(copy, obj);

      // replace refs with matching signatures with class objects
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // end for

      return copy;
    }
    return undefined;
  }
} // END ImgSettings
