// DO NOT EDIT.Generated 'MessageClass' named 'soundSettings' in 'WebApi.Messages' from '/home/wross/src/iSeries/hw/webapi-server/shared/definitions/class.soundSettings.json'

export function isSoundSettings(obj: any): obj is SoundSettings {
  return 'beep_enabled' in obj && true;
}

export interface SoundSettingsLike {
  /**
   * Enable/Disable the device beep sound.
   */
  beep_enabled: boolean;
}

export class SoundSettings /*extends MessageData implements ISoundSettings */ {
  /**
   * Enable/Disable the device beep sound.
   */
  beep_enabled: boolean;
  static parse(data: string): SoundSettings {
    const obj = JSON.parse(data);

    if (isSoundSettings(obj)) {
      return SoundSettings.copy(obj);
    }
    return undefined;
  }

  static copy(obj: SoundSettingsLike): SoundSettings {
    if (isSoundSettings(obj)) {
      let copy = new SoundSettings();
      copy = Object.assign(copy, obj);

      // replace refs with matching signatures with class objects
      // for all properties
      // end is class ref
      // end for

      return copy;
    }
    return undefined;
  }
} // END SoundSettings
