// DO NOT EDIT.Generated 'MessageClass' named 'rfConfigSettings' in 'WebApi.Messages' from '/home/wross/src/iSeries/hw/webapi-server/shared/definitions/class.rfConfigSettings.json'
import { DocSignerCertMode } from './DocSignerCertMode';
import { ExternalCertMode } from './ExternalCertMode';
import { TerminalType } from './TerminalType';

export function isRfConfigSettings(obj: any): obj is RfConfigSettings {
  return (
    'bac_fallback_enabled' in obj &&
    'cert_type_checking_enabled' in obj &&
    'check_csc_revocation' in obj &&
    'check_dsc_revocation' in obj &&
    'chip_in_use_timeout' in obj &&
    'csc_crl_mode' in obj &&
    'default_chip_baud_rate' in obj &&
    'doc_signer_cert_mode' in obj &&
    'eac_enabled' in obj &&
    'external_csc_mode' in obj &&
    'external_cvcerts_mode' in obj &&
    'external_dsc_mode' in obj &&
    'external_private_key_mode' in obj &&
    'force_extended_apdu' in obj &&
    'max_apdu_attempts' in obj &&
    'open_chip_timeout' in obj &&
    'pace_cam_enabled' in obj &&
    'read_binary_buffer_size' in obj &&
    'read_binary_use_efatr_if_present' in obj &&
    'read_edl' in obj &&
    'read_epassport' in obj &&
    'read_idl' in obj &&
    'sac_enabled' in obj &&
    'select_lds_application' in obj &&
    'terminal_access_rights' in obj &&
    'terminal_type' in obj &&
    'validate_security_setup_fields' in obj &&
    true
  );
}

export interface RfConfigSettingsLike {
  /**
   * Enable/Disable whether or not to try BAC if SAC fails.
   */
  bac_fallback_enabled: boolean;
  /**
   * Enable/Disable if certificate type (CV/DV/IS) checking is performed on certificates passed to the reader via callback.
   */
  cert_type_checking_enabled: boolean;
  /**
   * Enable/Disable checking Country Signer Certificate revocation status.
   */
  check_csc_revocation: boolean;
  /**
   * Enable/Disable checking Doc Signer Certificate revocation status.
   */
  check_dsc_revocation: boolean;
  /**
   * Specify how long the reader should wait (milliseconds) to open an RFID chip before giving up, if the chip is already in use.
   */
  chip_in_use_timeout: number;
  /**
   * Specify how to retrieve Certificate Revocation Lists (CRLs) for Country Signer Certificates (CSCs).
   */
  csc_crl_mode: ExternalCertMode;
  /**
   * Specify the default over-aid baud rate to use for RFID chip communication (not applicable to all RF readers).
   */
  default_chip_baud_rate: number;
  /**
   * Specify mode for loading the Doc Signer Certificate.
   */
  doc_signer_cert_mode: DocSignerCertMode;
  /**
   * Enable/Disable EAC for access to protected data groups, such as DG3 (requires EAC certificates for Terminal Authentication).
   */
  eac_enabled: boolean;
  /**
   * Specify mode for getting external Country Signer Certificates (CSCs).
   */
  external_csc_mode: ExternalCertMode;
  /**
   * Specify how to retrieve Country Verifying Certification Authority (CVCA) certificates when EAC is enabled for Terminal Authentication.
   */
  external_cvcerts_mode: ExternalCertMode;
  /**
   * Specify mode for getting external Doc Signer Certificates (DSCs).
   */
  external_dsc_mode: ExternalCertMode;
  /**
   * Specify how to retrieve Inspection System (IS) private keys when EAC is enabled for Terminal Authentication.
   */
  external_private_key_mode: ExternalCertMode;
  /**
   * Enable/Disable always using extended APDU with size specified in read_binary_buffer_size/puReadBinaryBufferSize.
   */
  force_extended_apdu: boolean;
  /**
   * Specify the the maximum number of attempts to try when communicating with the RFID chip.
   */
  max_apdu_attempts: number;
  /**
   * Specify how long the device should wait to open an RFID chip before giving up.
   */
  open_chip_timeout: number;
  /**
   * Enable/Disable PACE-CAM SAC algorithm.
   */
  pace_cam_enabled: boolean;
  /**
   * Specify the default buffer size to request from the RFID chip on every Read Binary APDU.
   */
  read_binary_buffer_size: number;
  /**
   * Enable/Disable whether the device will attempt to read the EF.ATR file on the RFID chip to determine Read Binary buffer size.
   */
  read_binary_use_efatr_if_present: boolean;
  /**
   * Enable/Disable attempting to read the eDL application.
   */
  read_edl: boolean;
  /**
   * Enable/Disable attempting to read the ePassport application.
   */
  read_epassport: boolean;
  /**
   * Enable/Disable attempting to read the iDL application.
   */
  read_idl: boolean;
  /**
   * Enable/Disable SAC.
   */
  sac_enabled: boolean;
  /**
   * Enable/Disable whether to select the LDS application on an RFID chip.
   */
  select_lds_application: boolean;
  /**
   * Specifies the Terminal Access Rights for EACv2 RF documents. Hex String. Default for TerminalType::INSPECTION_SYSTEM is &#39;03&#39;
   */
  terminal_access_rights: string;
  /**
   * Specify mode for the EACv2 terminal type (requires terminal certificates).
   */
  terminal_type: TerminalType;
  /**
   * Enable/Disable whether or not to perform extra validation on chip security setup fields for BAC/SAC (DocNum, DOB, DOE, etc.)  Default is length check only.
   */
  validate_security_setup_fields: boolean;
}

export class RfConfigSettings /*extends MessageData implements IRfConfigSettings */ {
  /**
   * Enable/Disable whether or not to try BAC if SAC fails.
   */
  bac_fallback_enabled: boolean;
  /**
   * Enable/Disable if certificate type (CV/DV/IS) checking is performed on certificates passed to the reader via callback.
   */
  cert_type_checking_enabled: boolean;
  /**
   * Enable/Disable checking Country Signer Certificate revocation status.
   */
  check_csc_revocation: boolean;
  /**
   * Enable/Disable checking Doc Signer Certificate revocation status.
   */
  check_dsc_revocation: boolean;
  /**
   * Specify how long the reader should wait (milliseconds) to open an RFID chip before giving up, if the chip is already in use.
   */
  chip_in_use_timeout: number;
  /**
   * Specify how to retrieve Certificate Revocation Lists (CRLs) for Country Signer Certificates (CSCs).
   */
  csc_crl_mode: ExternalCertMode;
  /**
   * Specify the default over-aid baud rate to use for RFID chip communication (not applicable to all RF readers).
   */
  default_chip_baud_rate: number;
  /**
   * Specify mode for loading the Doc Signer Certificate.
   */
  doc_signer_cert_mode: DocSignerCertMode;
  /**
   * Enable/Disable EAC for access to protected data groups, such as DG3 (requires EAC certificates for Terminal Authentication).
   */
  eac_enabled: boolean;
  /**
   * Specify mode for getting external Country Signer Certificates (CSCs).
   */
  external_csc_mode: ExternalCertMode;
  /**
   * Specify how to retrieve Country Verifying Certification Authority (CVCA) certificates when EAC is enabled for Terminal Authentication.
   */
  external_cvcerts_mode: ExternalCertMode;
  /**
   * Specify mode for getting external Doc Signer Certificates (DSCs).
   */
  external_dsc_mode: ExternalCertMode;
  /**
   * Specify how to retrieve Inspection System (IS) private keys when EAC is enabled for Terminal Authentication.
   */
  external_private_key_mode: ExternalCertMode;
  /**
   * Enable/Disable always using extended APDU with size specified in read_binary_buffer_size/puReadBinaryBufferSize.
   */
  force_extended_apdu: boolean;
  /**
   * Specify the the maximum number of attempts to try when communicating with the RFID chip.
   */
  max_apdu_attempts: number;
  /**
   * Specify how long the device should wait to open an RFID chip before giving up.
   */
  open_chip_timeout: number;
  /**
   * Enable/Disable PACE-CAM SAC algorithm.
   */
  pace_cam_enabled: boolean;
  /**
   * Specify the default buffer size to request from the RFID chip on every Read Binary APDU.
   */
  read_binary_buffer_size: number;
  /**
   * Enable/Disable whether the device will attempt to read the EF.ATR file on the RFID chip to determine Read Binary buffer size.
   */
  read_binary_use_efatr_if_present: boolean;
  /**
   * Enable/Disable attempting to read the eDL application.
   */
  read_edl: boolean;
  /**
   * Enable/Disable attempting to read the ePassport application.
   */
  read_epassport: boolean;
  /**
   * Enable/Disable attempting to read the iDL application.
   */
  read_idl: boolean;
  /**
   * Enable/Disable SAC.
   */
  sac_enabled: boolean;
  /**
   * Enable/Disable whether to select the LDS application on an RFID chip.
   */
  select_lds_application: boolean;
  /**
   * Specifies the Terminal Access Rights for EACv2 RF documents. Hex String. Default for TerminalType::INSPECTION_SYSTEM is &#39;03&#39;
   */
  terminal_access_rights: string;
  /**
   * Specify mode for the EACv2 terminal type (requires terminal certificates).
   */
  terminal_type: TerminalType;
  /**
   * Enable/Disable whether or not to perform extra validation on chip security setup fields for BAC/SAC (DocNum, DOB, DOE, etc.)  Default is length check only.
   */
  validate_security_setup_fields: boolean;
  static parse(data: string): RfConfigSettings {
    const obj = JSON.parse(data);

    if (isRfConfigSettings(obj)) {
      return RfConfigSettings.copy(obj);
    }
    return undefined;
  }

  static copy(obj: RfConfigSettingsLike): RfConfigSettings {
    if (isRfConfigSettings(obj)) {
      let copy = new RfConfigSettings();
      copy = Object.assign(copy, obj);

      // replace refs with matching signatures with class objects
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // end for

      return copy;
    }
    return undefined;
  }
} // END RfConfigSettings
