/* eslint-disable no-async-promise-executor */

import { Injectable, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { LogService } from '@core/services/log.service';
import { ReaderDataType } from '@data/models/document-reader/lib/ReaderDataType';
import { DocumentReaderStateService } from './document-reader-state.service'
import { WinBridgeService } from '@core/win-bridge/win-bridge.service';
import { EIdentityDocumentType } from '@core/enums/identity-document-type.enum';

@Injectable({
  providedIn: null,
})
export class ThalesDocumentReaderConnectedStateService extends DocumentReaderStateService implements OnDestroy {
  constructor(
    private winBridgeService: WinBridgeService,
    logService: LogService
  ) {
    super(logService);
    logService.debug(`[ThalesDocumentReaderConnectedStateService]constructor()`);
    this.initSubscribers();
  }

  public async ngOnDestroy(): Promise<void> {
    await this.stop();
    super.ngOnDestroy();
  }

  public async init(_expectedData: ReaderDataType[], _settings): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        
        await this.winBridgeService.InitializeScanner(EIdentityDocumentType.PASSPORT);
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }

  public async connect(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.winBridgeService.ensureScannerIsConnected();
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }

  private initSubscribers(): void {
    this.winBridgeService.onScannerIsConnected()
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (isConnected) => {
        if (isConnected) {
          this.isInitializing$.next(false);
        }
      });

    this.winBridgeService.onDocumentDataCaptured()
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (data) => {
        this.scannedData$.next(data);
        this.isProcessing$.next(false);
        this.isCompleted$.next(true);
      });

      this.isTimedOut$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
        if (value) {
          this.stop();
        }
      });
  }

  public async capture(documentType: EIdentityDocumentType): Promise<void> {
    this.logService.debug(`[DocumentReaderDataState]capture()`);
    this.isProcessing$.next(true);
    this.startTimeoutTimer(30000, this.isProcessing$);
    await this.winBridgeService.scanDocument(documentType);
  }

  public async stop(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.winBridgeService.shutdown();
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }
}
