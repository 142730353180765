// DO NOT EDIT.Generated 'MessageClass' named 'settingsMessage' in 'WebApi.Messages' from '/home/wross/src/iSeries/hw/webapi-server/shared/definitions/class.settingsMessage.json'
import { DeviceSettings, isDeviceSettings } from './deviceSettings';

export function isSettingsMessage(obj: any): obj is SettingsMessage {
  return 'settings' in obj && true;
}

export interface SettingsMessageLike {
  /**
   * Document reader settings.  Equivalent to MMMReader_GetSettings()
   */
  settings: DeviceSettings;
}

export class SettingsMessage /*extends MessageData implements ISettingsMessage */ {
  /**
   * Document reader settings.  Equivalent to MMMReader_GetSettings()
   */
  settings: DeviceSettings;
  static parse(data: string): SettingsMessage {
    const obj = JSON.parse(data);

    if (isSettingsMessage(obj)) {
      return SettingsMessage.copy(obj);
    }
    return undefined;
  }

  static copy(obj: SettingsMessageLike): SettingsMessage {
    if (isSettingsMessage(obj)) {
      let copy = new SettingsMessage();
      copy = Object.assign(copy, obj);

      // replace refs with matching signatures with class objects
      // for all properties
      // is class ref
      // end if optional
      // else !isArray
      if (isDeviceSettings(obj.settings)) {
        copy.settings = DeviceSettings.copy(obj.settings);
      } else {
        throw Error('Missing required property settings of SettingsMessage');
      }
      // end if isArray
      // end optional
      // end is class ref
      // end for

      return copy;
    }
    return undefined;
  }
} // END SettingsMessage
