export enum DocumentReaderConnectionEvent {
  OK = 0,
  CONNECT = 1,
  CONNECTED = 2,
  CLOSE = 3,
  CLOSED = 4,
  CAPTURE = 5,
  CAPTURED = 6,
  DISCONNECT = 7,
  NOT_AUTHORIZED = 8,
  SESSION_OPEN = 9,
  SESSION_OPENED = 10,
  SESSION_CLOSE = 11,
  SESSION_CLOSED = 12,
  CAPTURE_ERROR = 13,
  INVALID_SESSION = 14,
  SESSION_IN_USE = 15,
  RETRIEVE_IMAGE = 16,
  RETURNED_IMAGE = 17,
  RETRIEVE_BARCODE = 18,
  RETURNED_BARCODE = 19,
  RETRIEVE_MRZ = 20,
  RETURNED_MRZ = 21,
  INVALID_KEY = 22,
  CAPTURE_DOCFOUND = 23,
  CAPTURE_IMAGE_CAPTURED = 24,
  CAPTURE_MRZ = 25,
  CAPTURE_BARCODE = 26,
  RETRIEVE_ERROR = 27,
  INVALID_COMMAND = 28,
  CAPTURE_SMARTCARD_FILE = 29,
  GET_EXTRADATA = 30,
  EXTRADATA = 31,
  GET_SETTINGS = 32,
  RETURNED_SETTINGS = 33,
  GET_SETTINGS_ERROR = 34,
  SET_SETTINGS = 35,
  SETTINGS_UPDATED = 36,
  SET_SETTINGS_ERROR = 37,
  CAPTURE_STOP = 38,
  CAPTURE_STOPPED = 39,
  CAPTURE_STOP_ERROR = 40,
  CAPTURE_AAMVA = 41,
  LISTEN_START = 42,
  LISTEN_STOP = 43,
  LISTEN_STARTED = 44,
  LISTEN_STOPPED = 45,
  EVENT = 46,
  GET_LOGS = 47,
  RETURNED_LOGS = 48,
  GET_LOGS_ERROR = 49,
  CAPTURE_DATA = 50,
  CAPTURE_DATA_CAPTURED = 51,
  RETRIEVE_DATA = 52,
  RETURNED_DATA = 53,
  LISTEN_ERROR_START = 54,
  LISTEN_ERROR_STOP = 55,
  LISTEN_ERROR_STARTED = 56,
  LISTEN_ERROR_STOPPED = 57,
  ERROR = 58,
  INTERNAL_ERROR = 59,
  UNPROVISIONED_DEVICE = 60,
  ASSUREID_CREDENTIALS = 61,
  ASSUREID_VERIFY_DOCUMENT = 62,
  ASSUREID_RETRIEVE_RESULTS = 63,
  ASSUREID_INSTANCE_ID = 64,
  ASSUREID_RESULTS = 65,
  ASSUREID_ERROR = 66,
  DEVICE_INFO = 70,
  FIRMWARE_UPDATE = 71,
  FIRMWARE_UPDATE_RESULT = 72,
  CHECK_FIRMWARE_UPDATE = 73,
  CHECK_FIRMWARE_UPDATE_RESULT = 74,
  LISTEN_UPDATE_START = 75,
  LISTEN_UPDATE_STARTED = 76,
  LISTEN_UPDATE_STOP = 76,
  LISTEN_UPDATE_STOPPED = 77,
  UPDATE_STATUS = 78,
  UPLOAD = 79,
  UPLOAD_RECEIVED = 80,
  REBOOT = 81,
  REBOOTING = 82,
}
