// DO NOT EDIT.Generated 'MessageClass' named 'dataToSendSettings' in 'WebApi.Messages' from '/home/wross/src/iSeries/hw/webapi-server/shared/definitions/class.dataToSendSettings.json'
import { CoaxIRCameraMode } from './CoaxIRCameraMode';
import { CoaxVisibleCameraMode } from './CoaxVisibleCameraMode';
import { IRCameraMode } from './IRCameraMode';
import { UVCameraMode } from './UVCameraMode';
import { VisibleCameraMode } from './VisibleCameraMode';

export function isDataToSendSettings(obj: any): obj is DataToSendSettings {
  return (
    'aamva' in obj &&
    'auto_orientation_barcode' in obj &&
    'auto_orientation_face' in obj &&
    'auto_orientation_magstripe' in obj &&
    'auto_orientation_mrz' in obj &&
    'azteccode' in obj &&
    'barcode_image' in obj &&
    'coax_ir_image' in obj &&
    'coax_visible_image' in obj &&
    'codeline_data' in obj &&
    'datamatrix' in obj &&
    'ir_image' in obj &&
    'onedbarcodes' in obj &&
    'pdf417' in obj &&
    'photo_image' in obj &&
    'qrcode' in obj &&
    'smart_card' in obj &&
    'uv_image' in obj &&
    'visible_image' in obj &&
    true
  );
}

export interface DataToSendSettingsLike {
  /**
   * Parse AAMVA data from PDF417 barcodes
   */
  aamva: boolean;
  /**
   * Enable/Disable auto-orienting document by PDF417 Barcode.
   */
  auto_orientation_barcode: boolean;
  /**
   * Enable/Disable auto-orienting document by Face.
   */
  auto_orientation_face: boolean;
  /**
   * Enable/Disable auto-orienting document by Magstripe.
   */
  auto_orientation_magstripe: boolean;
  /**
   * Enable/Disable auto-orienting document by MRZ.
   */
  auto_orientation_mrz: boolean;
  /**
   * Enable scanning for Aztec barcodes.
   */
  azteccode: boolean;
  /**
   * Return the image of the barcode
   */
  barcode_image: boolean;
  /**
   * Set the Coax IR camera mode.
   */
  coax_ir_image: CoaxIRCameraMode;
  /**
   * Set the Coax Visible camera mode.
   */
  coax_visible_image: CoaxVisibleCameraMode;
  /**
   * Return the codeline data.
   */
  codeline_data: boolean;
  /**
   * Enable scanning for Data Matrix barodes.
   */
  datamatrix: boolean;
  /**
   * Set the IR camera mode.
   */
  ir_image: IRCameraMode;
  /**
   * Enable scanning for 1D barcodes.
   */
  onedbarcodes: boolean;
  /**
   * Enable scanning for PDF 417 barodes.
   */
  pdf417: boolean;
  /**
   * Set the Photo capture mode.
   */
  photo_image: VisibleCameraMode;
  /**
   * Enable scanning for QR Codes.
   */
  qrcode: boolean;
  /**
   * Enable/Disable smart card reading.
   */
  smart_card: boolean;
  /**
   * Set the UV camera mode.
   */
  uv_image: UVCameraMode;
  /**
   * Set the Visable Image camera mode
   */
  visible_image: VisibleCameraMode;
}

export class DataToSendSettings /*extends MessageData implements IDataToSendSettings */ {
  /**
   * Parse AAMVA data from PDF417 barcodes
   */
  aamva: boolean;
  /**
   * Enable/Disable auto-orienting document by PDF417 Barcode.
   */
  auto_orientation_barcode: boolean;
  /**
   * Enable/Disable auto-orienting document by Face.
   */
  auto_orientation_face: boolean;
  /**
   * Enable/Disable auto-orienting document by Magstripe.
   */
  auto_orientation_magstripe: boolean;
  /**
   * Enable/Disable auto-orienting document by MRZ.
   */
  auto_orientation_mrz: boolean;
  /**
   * Enable scanning for Aztec barcodes.
   */
  azteccode: boolean;
  /**
   * Return the image of the barcode
   */
  barcode_image: boolean;
  /**
   * Set the Coax IR camera mode.
   */
  coax_ir_image: CoaxIRCameraMode;
  /**
   * Set the Coax Visible camera mode.
   */
  coax_visible_image: CoaxVisibleCameraMode;
  /**
   * Return the codeline data.
   */
  codeline_data: boolean;
  /**
   * Enable scanning for Data Matrix barodes.
   */
  datamatrix: boolean;
  /**
   * Set the IR camera mode.
   */
  ir_image: IRCameraMode;
  /**
   * Enable scanning for 1D barcodes.
   */
  onedbarcodes: boolean;
  /**
   * Enable scanning for PDF 417 barodes.
   */
  pdf417: boolean;
  /**
   * Set the Photo capture mode.
   */
  photo_image: VisibleCameraMode;
  /**
   * Enable scanning for QR Codes.
   */
  qrcode: boolean;
  /**
   * Enable/Disable smart card reading.
   */
  smart_card: boolean;
  /**
   * Set the UV camera mode.
   */
  uv_image: UVCameraMode;
  /**
   * Set the Visable Image camera mode
   */
  visible_image: VisibleCameraMode;
  static parse(data: string): DataToSendSettings {
    const obj = JSON.parse(data);

    if (isDataToSendSettings(obj)) {
      return DataToSendSettings.copy(obj);
    }
    return undefined;
  }

  static copy(obj: DataToSendSettingsLike): DataToSendSettings {
    if (isDataToSendSettings(obj)) {
      let copy = new DataToSendSettings();
      copy = Object.assign(copy, obj);

      // replace refs with matching signatures with class objects
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // end for

      return copy;
    }
    return undefined;
  }
} // END DataToSendSettings
