import { DocumentReaderDataType } from './document-reader-data-types.enum';
export class DocumentReaderModule {
  open_connection: (address, apiId, apiKey, protocol?, port?) => any;
  close_connection: () => any;
  start_session: () => any;
  end_session: () => any;
  get_device_info: () => any;
  capture: () => any;
  /*   ConnectionEvents;
  ReaderDataTypes;
  EventCodes;
  ENVIRONMENT;
  locateFile;
  inspect; */
  print: () => any;
  printErr: () => any;
  /*   arguments;
  thisProgram; */
  quit: () => any;
  on: (event, handler) => any;
  send_message: (message) => any;
  run: () => any;
  getNextI: () => number;
  read: () => any;
  getEventCode: (id) => any;
  getReaderDataType: (type) => DocumentReaderDataType;
  webapi_enable_logging: (level: string) => any;
}
