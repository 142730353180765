/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Injectable } from '@angular/core';
import { WinBridgeCommand, WinBridgeInvokedMethod } from './win-bridge-commands.enum';
import { blobToBase64 } from '@core/utils/utilities.util';
import { Observable, Subject, fromEvent, map, of } from 'rxjs';
import { Printer } from '@core/model/printers/printer.model';
import { isHostedInWin, webview } from './win.utils';
import { PrintResponse } from '@core/model/printers/print-response.model';
import { WinAppEvents } from './win-bridge-events';
import { ScannedData } from './scanned-data.model';
import { EIdentityDocumentType } from '@core/enums/identity-document-type.enum';

@Injectable({
  providedIn: 'root'
})
export class WinBridgeService {
  window = window as any;
  private onBadgePrintedReceived$ = new Subject<boolean>();
  
  constructor() { 
    this.bindToWinAppEvents();
  }

  public get onBadgePrintedReceived(): Observable<boolean> {
    return this.onBadgePrintedReceived$.asObservable();
  }

  public async ensureScannerIsConnected(): Promise<void> {
    this.sendRawMessageToHost(WinBridgeCommand.GetConnected);
  }

  public onScannerIsConnected(): Observable<boolean> {
    return fromEvent<CustomEvent>(window, WinAppEvents.scannerConnected).pipe(
      map((customeEvent) => {
        if (!customeEvent?.detail) {
          return false;
        }
        return true;
      })
    );
  }

  public onDocumentDataCaptured(): Observable<ScannedData> {
    return fromEvent<CustomEvent>(window, WinAppEvents.scanComplete).pipe(
      map((customeEvent) => {
        if (!customeEvent?.detail) {
          return null;
        }
        return customeEvent.detail as ScannedData;
      })
    );
  }

  async shutdown() {
    this.sendRawMessageToHost(WinBridgeCommand.Shutdown);
  }

  async getConnectedPrinters() {
    this.sendRawMessageToHost(WinBridgeCommand.GetConnectedPrinters);
  }

  public getPrinters(): Observable<Printer[]> {
    this.getConnectedPrinters();
      return fromEvent<CustomEvent>(window, WinAppEvents.getPrintersComplete).pipe(
        map((customeEvent) => {
          if (!customeEvent?.detail) {
            return [];
          }
          return customeEvent?.detail as Printer[];
        })
      );
  }

  async printBadge(printerName: string, paperWidth: string, badgeBlob: Blob) {
    const blobBase64 = await blobToBase64(badgeBlob);
    this.sendInvokeMessageToHost(WinBridgeInvokedMethod.Print, [printerName, paperWidth, blobBase64]);
  }

  async scanDocument(documentType: EIdentityDocumentType) {
    this.sendInvokeMessageToHost(WinBridgeInvokedMethod.ScanDocument, [documentType]);
  }

  async InitializeScanner(documentType: EIdentityDocumentType) {
    this.sendInvokeMessageToHost(WinBridgeInvokedMethod.InitializeScanner, [documentType]);
  }

  async setRegistrationKey(registrationKey: string) {
    this.sendInvokeMessageToHost(WinBridgeInvokedMethod.SetDeviceRegistrationKey, [registrationKey]);
  }

  async reloadApplication() {
    this.sendRawMessageToHost(WinBridgeCommand.ReloadApplication);
  }

  async refreshApplication() {
    this.sendRawMessageToHost(WinBridgeCommand.RefreshApplication);
  }

  async getWindowsDeviceId() {
    this.sendRawMessageToHost(WinBridgeCommand.GetDeviceId);
  }

  async getWindowsDeviceRegKey() {
    this.sendRawMessageToHost(WinBridgeCommand.GetDeviceRegistrationKey);
  }

  async getWindowsDeviceSerialNumber() {
    this.sendRawMessageToHost(WinBridgeCommand.GetDeviceSerialNumber);
  }

  async getAppVersion() {
    this.sendRawMessageToHost(WinBridgeCommand.GetAppVersion);
  }


  public getDeviceId(): Observable<string> {
    if(this.window.kioskApp?.deviceId) {
      return of(this.window.kioskApp?.deviceId);
    }
    this.getWindowsDeviceId();
      return fromEvent<CustomEvent>(window, WinAppEvents.getDeviceIdComplete).pipe(
        map((customeEvent) => {
          if (!customeEvent?.detail) {
            return undefined;
          }
          this.window.kioskApp.deviceId = customeEvent.detail;
          return customeEvent?.detail as string;
        })
      );
  }

  public getSerialNumber(): Observable<string> {
    if(this.window.kioskApp?.serialNumber) {
      return of(this.window.kioskApp?.serialNumber);
    }
    this.getWindowsDeviceSerialNumber();
      return fromEvent<CustomEvent>(window, WinAppEvents.getSerialNumberComplete).pipe(
        map((customeEvent) => {
          if (!customeEvent?.detail) {
            return undefined;
          }
          this.window.kioskApp.serialNumber = customeEvent.detail;
          return customeEvent?.detail as string;
        })
      );
  }

  public getRegistrationKey(): Observable<string> {
    this.getWindowsDeviceRegKey();
      return fromEvent<CustomEvent>(window, WinAppEvents.getRegistrationKeyComplete).pipe(
        map((customeEvent) => {
          if (!customeEvent?.detail) {
            return undefined;
          }
          this.window.kioskApp.registrationKey = customeEvent.detail;
          return customeEvent.detail as string;
        })
      );
  }

  public getAppVersionNUmber(): Observable<string> {
    this.getAppVersion();
      return fromEvent<CustomEvent>(window, WinAppEvents.getAppVersionComplete).pipe(
        map((customeEvent) => {
          if (!customeEvent?.detail) {
            return undefined;
          }
          this.window.kioskApp.registrationKey = customeEvent.detail;
          return customeEvent.detail as string;
        })
      );
  }
  
  private async sendRawMessageToHost(message: string) {
      await this.sendMessageToHost(0, message);
  }

  private async sendInvokeMessageToHost(methodName: string, paramValues: string[]) {
      if (typeof paramValues !== 'undefined') {
          if (!Array.isArray(paramValues)) {
              paramValues = [paramValues];
          }
          for (let i = 0; i < paramValues.length; i++) {
              paramValues[i] = JSON.stringify(paramValues[i]);
          }
      }

      await this.sendMessageToHost(1, JSON.stringify({ "MethodName": methodName, "ParamValues": paramValues }));
  }

  private async sendMessageToHost(messageType: any, messageContent: string) {
      const message = JSON.stringify({ "MessageType": messageType, "MessageContent": messageContent });

      if (isHostedInWin()) {
        // Windows WebView2
        webview().postMessage(message);
    }
    else if (this.window.webkit?.messageHandlers?.webwindowinterop) {
        // iOS and MacCatalyst WKWebView
        this.window.webkit.messageHandlers.webwindowinterop.postMessage(message);
    }
  }

  private onBadgePrintedComplete(customeEvent) {
    const printResponse = customeEvent?.detail as PrintResponse;
    this.onBadgePrintedReceived$.next(printResponse.isPrinted);
    if(!printResponse.isPrinted) {
      console.log('printing error: ', printResponse.error);
    }
  }

  private bindToWinAppEvents() {
    this.window.addEventListener(
      WinAppEvents.printBadgeComplete,
      this.onBadgePrintedComplete.bind(this),
      false
    );

    if (!this.window.kioskApp) {
      this.window.kioskApp = {
        registrationKey: null,
        serialNumber: null,
        deviceId: null
      };
    }
  }
}
