// DO NOT EDIT.Generated 'MessageClass' named 'loggingSettings' in 'WebApi.Messages' from '/home/wross/src/iSeries/hw/webapi-server/shared/definitions/class.loggingSettings.json'

export function isLoggingSettings(obj: any): obj is LoggingSettings {
  return 'log_level' in obj && true;
}

export interface LoggingSettingsLike {
  /**
   * Configure the SDK log level (0-5).
   */
  log_level: number;
}

export class LoggingSettings /*extends MessageData implements ILoggingSettings */ {
  /**
   * Configure the SDK log level (0-5).
   */
  log_level: number;
  static parse(data: string): LoggingSettings {
    const obj = JSON.parse(data);

    if (isLoggingSettings(obj)) {
      return LoggingSettings.copy(obj);
    }
    return undefined;
  }

  static copy(obj: LoggingSettingsLike): LoggingSettings {
    if (isLoggingSettings(obj)) {
      let copy = new LoggingSettings();
      copy = Object.assign(copy, obj);

      // replace refs with matching signatures with class objects
      // for all properties
      // end is class ref
      // end for

      return copy;
    }
    return undefined;
  }
} // END LoggingSettings
