// DO NOT EDIT.Generated 'MessageClass' named 'ocrSettings' in 'WebApi.Messages' from '/home/wross/src/iSeries/hw/webapi-server/shared/definitions/class.ocrSettings.json'

export function isOcrSettings(obj: any): obj is OcrSettings {
  return (
    'despeckle_codeline_image' in obj &&
    'scale_down_dpi' in obj &&
    'visa_label_check' in obj &&
    true
  );
}

export interface OcrSettingsLike {
  /**
   * Enable/Disable OCR processing to despeckle the codeline image.
   */
  despeckle_codeline_image: boolean;
  /**
   * Configure the target DPI of the OCR image for higher resoultion scanners.
   */
  scale_down_dpi: number;
  /**
   * Enable/Disable possibly performing an extra OCR attempt for higher MRZs, as seen on VISA pages.
   */
  visa_label_check: boolean;
}

export class OcrSettings /*extends MessageData implements IOcrSettings */ {
  /**
   * Enable/Disable OCR processing to despeckle the codeline image.
   */
  despeckle_codeline_image: boolean;
  /**
   * Configure the target DPI of the OCR image for higher resoultion scanners.
   */
  scale_down_dpi: number;
  /**
   * Enable/Disable possibly performing an extra OCR attempt for higher MRZs, as seen on VISA pages.
   */
  visa_label_check: boolean;
  static parse(data: string): OcrSettings {
    const obj = JSON.parse(data);

    if (isOcrSettings(obj)) {
      return OcrSettings.copy(obj);
    }
    return undefined;
  }

  static copy(obj: OcrSettingsLike): OcrSettings {
    if (isOcrSettings(obj)) {
      let copy = new OcrSettings();
      copy = Object.assign(copy, obj);

      // replace refs with matching signatures with class objects
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // for all properties
      // end is class ref
      // end for

      return copy;
    }
    return undefined;
  }
} // END OcrSettings
