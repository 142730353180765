// DO NOT EDIT.Generated 'MessageClass' named 'deviceSettings' in 'WebApi.Messages' from '/home/wross/src/iSeries/hw/webapi-server/shared/definitions/class.deviceSettings.json'
import { DataToSendSettings, isDataToSendSettings } from './dataToSendSettings';
import { DocDetectSettings, isDocDetectSettings } from './docDetectSettings';
import { ImgSettings, isImgSettings } from './imgSettings';
import { isLoggingSettings, LoggingSettings } from './loggingSettings';
import { isOcrSettings, OcrSettings } from './ocrSettings';
import { isRfConfigSettings, RfConfigSettings } from './rfConfigSettings';
import { isRfDataToSendSettings, RfDataToSendSettings } from './rfDataToSendSettings';
import { isSoundSettings, SoundSettings } from './soundSettings';

export function isDeviceSettings(obj: any): obj is DeviceSettings {
  return (
    'data_to_send' in obj &&
    'doc_detect' in obj &&
    'img' in obj &&
    'logging' in obj &&
    'ocr' in obj &&
    'rf_config' in obj &&
    'rf_data_to_send' in obj &&
    'sound' in obj &&
    true
  );
}

export interface DeviceSettingsLike {
  /**
   * Settings to specify data items for the reader to process and return to the client.  Equivalent to DataToSend.
   */
  data_to_send: DataToSendSettings;
  /**
   * Document Detection settings.  Equivalent to DocDetect.
   */
  doc_detect: DocDetectSettings;
  /**
   * Image settings.  Equivalent to Img / ImageOverrides.
   */
  img: ImgSettings;
  /**
   * Logging settings.  Equivalent to Logging.
   */
  logging: LoggingSettings;
  /**
   * OCR settings.  Equivalent to OCRContext and OCR settings.
   */
  ocr: OcrSettings;
  /**
   * Settings to specify RF configuration for ePassport security, etc.  Equivalent to RFProcSettings.
   */
  rf_config: RfConfigSettings;
  /**
   * Settings to specify RF data items for the reader to process and return to the client.  Equivalent to RFDataToSend.
   */
  rf_data_to_send: RfDataToSendSettings;
  /**
   * Sound settings.  Equivalent to Sound settings.
   */
  sound: SoundSettings;
}

export class DeviceSettings /*extends MessageData implements IDeviceSettings */ {
  /**
   * Settings to specify data items for the reader to process and return to the client.  Equivalent to DataToSend.
   */
  data_to_send: DataToSendSettings;
  /**
   * Document Detection settings.  Equivalent to DocDetect.
   */
  doc_detect: DocDetectSettings;
  /**
   * Image settings.  Equivalent to Img / ImageOverrides.
   */
  img: ImgSettings;
  /**
   * Logging settings.  Equivalent to Logging.
   */
  logging: LoggingSettings;
  /**
   * OCR settings.  Equivalent to OCRContext and OCR settings.
   */
  ocr: OcrSettings;
  /**
   * Settings to specify RF configuration for ePassport security, etc.  Equivalent to RFProcSettings.
   */
  rf_config: RfConfigSettings;
  /**
   * Settings to specify RF data items for the reader to process and return to the client.  Equivalent to RFDataToSend.
   */
  rf_data_to_send: RfDataToSendSettings;
  /**
   * Sound settings.  Equivalent to Sound settings.
   */
  sound: SoundSettings;
  static parse(data: string): DeviceSettings {
    const obj = JSON.parse(data);

    if (isDeviceSettings(obj)) {
      return DeviceSettings.copy(obj);
    }
    return undefined;
  }

  static copy(obj: DeviceSettingsLike): DeviceSettings {
    if (isDeviceSettings(obj)) {
      let copy = new DeviceSettings();
      copy = Object.assign(copy, obj);

      // replace refs with matching signatures with class objects
      // for all properties
      // is class ref
      // end if optional
      // else !isArray
      if (isDataToSendSettings(obj.data_to_send)) {
        copy.data_to_send = DataToSendSettings.copy(obj.data_to_send);
      } else {
        throw Error('Missing required property data_to_send of DeviceSettings');
      }
      // end if isArray
      // end optional
      // end is class ref
      // for all properties
      // is class ref
      // end if optional
      // else !isArray
      if (isDocDetectSettings(obj.doc_detect)) {
        copy.doc_detect = DocDetectSettings.copy(obj.doc_detect);
      } else {
        throw Error('Missing required property doc_detect of DeviceSettings');
      }
      // end if isArray
      // end optional
      // end is class ref
      // for all properties
      // is class ref
      // end if optional
      // else !isArray
      if (isImgSettings(obj.img)) {
        copy.img = ImgSettings.copy(obj.img);
      } else {
        throw Error('Missing required property img of DeviceSettings');
      }
      // end if isArray
      // end optional
      // end is class ref
      // for all properties
      // is class ref
      // end if optional
      // else !isArray
      if (isLoggingSettings(obj.logging)) {
        copy.logging = LoggingSettings.copy(obj.logging);
      } else {
        throw Error('Missing required property logging of DeviceSettings');
      }
      // end if isArray
      // end optional
      // end is class ref
      // for all properties
      // is class ref
      // end if optional
      // else !isArray
      if (isOcrSettings(obj.ocr)) {
        copy.ocr = OcrSettings.copy(obj.ocr);
      } else {
        throw Error('Missing required property ocr of DeviceSettings');
      }
      // end if isArray
      // end optional
      // end is class ref
      // for all properties
      // is class ref
      // end if optional
      // else !isArray
      if (isRfConfigSettings(obj.rf_config)) {
        copy.rf_config = RfConfigSettings.copy(obj.rf_config);
      } else {
        throw Error('Missing required property rf_config of DeviceSettings');
      }
      // end if isArray
      // end optional
      // end is class ref
      // for all properties
      // is class ref
      // end if optional
      // else !isArray
      if (isRfDataToSendSettings(obj.rf_data_to_send)) {
        copy.rf_data_to_send = RfDataToSendSettings.copy(obj.rf_data_to_send);
      } else {
        throw Error('Missing required property rf_data_to_send of DeviceSettings');
      }
      // end if isArray
      // end optional
      // end is class ref
      // for all properties
      // is class ref
      // end if optional
      // else !isArray
      if (isSoundSettings(obj.sound)) {
        copy.sound = SoundSettings.copy(obj.sound);
      } else {
        throw Error('Missing required property sound of DeviceSettings');
      }
      // end if isArray
      // end optional
      // end is class ref
      // end for

      return copy;
    }
    return undefined;
  }
} // END DeviceSettings
