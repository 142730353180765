/* eslint-disable no-async-promise-executor */

import { lastValueFrom } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';

import { DataCaptureResult } from '@data/models/document-reader/lib/dataCaptureResult';
import { DataResponse } from '@data/models/document-reader/lib/dataResponse';
import { DocumentReaderCaptureDataCaptured } from '@data/models/document-reader/document-reader-capture-data-captured.model';
import { DocumentReaderConnectionEvent } from '@data/models/document-reader/document-reader-connection-events.enum';
import { DocumentReaderReturnedData } from '@data/models/document-reader/document-reader-returned-data.model';
import { ThalesDocumentReaderApiService } from '@data/services/document-readers/thales-document-reader-api.service';
import { LogService } from '@core/services/log.service';
import { ReaderDataType } from '@data/models/document-reader/lib/ReaderDataType';
import { DocumentReaderStateService } from './document-reader-state.service'
import { ScannedData } from '@core/win-bridge/scanned-data.model';

@Injectable({
  providedIn: null,
})
export class ThalesDocumentReaderApiStateService extends DocumentReaderStateService implements OnDestroy {
  private settings: any;
  private expectedData: ReaderDataType[] = [];
  private retrievedData: DataCaptureResult[] = [];
  private completedData: DataResponse[] = [];

  constructor(
    private documentReaderService: ThalesDocumentReaderApiService,
    logService: LogService
  ) {
    super(logService);
    logService.debug(`[ThalesDocumentReaderApiStateService]constructor()`);
    this.initSubscribers();
  }

  public async ngOnDestroy(): Promise<void> {
    await this.stop();
    super.ngOnDestroy();
  }

  public async init(expectedData: ReaderDataType[], settings): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.settings = settings;
      this.expectedData = expectedData;
      this.retrievedData = [];
      this.completedData = [];

      this.documentReaderService.isReady
        .pipe(takeUntil(this.destroy$))
        .subscribe(async (isReady) => {
          try {
            if (isReady) {
              await this.start();
              resolve(true);
            }
          } catch (error) {
            reject(error);
          }
        });
    });
  }

  public connect(): Promise<boolean> {
    return Promise.resolve(true);
  }

  private initSubscribers(): void {
    this.startTimeoutTimer(10000, this.isInitializing$);
    this.documentReaderService.isListening
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (value) => {
        if(value) {
          this.isInitializing$.next(false);
        }
      });

    this.documentReaderService.onCaptureDocFound
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value) {
          this.isConnecting$.next(false);
          this.isProcessing$.next(true);
          this.startTimeoutTimer(25000, this.isProcessing$);
        }
      });

    this.documentReaderService.onCaptured.pipe(takeUntil(this.destroy$)).subscribe(async () => {
      this.processRetrivedData();
    });

    this.documentReaderService.onCaptureDataCaptured
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (documentReaderCaptureDataCaptured: DocumentReaderCaptureDataCaptured) => {
        if (documentReaderCaptureDataCaptured) {
          if (this.expectedData.includes(documentReaderCaptureDataCaptured.type)) {
            this.retrievedData.push(documentReaderCaptureDataCaptured);
          }
        }
      });

    this.documentReaderService.onReturnedData
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (value) => {
        if (value) {
          this.completedData.push(value);
          this.addResponse(value);
          if (this.retrievedData.length === this.completedData.length) {
            this.isProcessing$.next(false);
            this.isCompleted$.next(true);
          }
        }
      });

    this.documentReaderService.onError.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value) {
        const errors = this.errors$.value;
        errors.push(value);
        this.errors$.next(errors);
      }
    });
  }

  private processRetrivedData(): void {
    for (const data of this.retrievedData) {
      this.documentReaderService.sendMessage(DocumentReaderConnectionEvent.RETRIEVE_DATA, {
        id: data.id,
      });
    }
  }

  private addResponse(documentReaderReturnedData: DocumentReaderReturnedData): void {
    this.scannedData$.pipe(take(1)).subscribe(data => {
      const scannedData = data? data as ScannedData: {} as ScannedData;
      switch (documentReaderReturnedData.type) {
        case ReaderDataType.IMAGEPHOTO:
          scannedData.PhotoImage = documentReaderReturnedData?.img?.data;
          this.scannedData$.next(scannedData);
          break;
        case ReaderDataType.CODELINE_DATA:
          scannedData.FirstName = documentReaderReturnedData?.codeline_data?.Forename;
          scannedData.LastName = documentReaderReturnedData?.codeline_data?.Surname;
          scannedData.DocumentType = documentReaderReturnedData?.codeline_data?.DocType;
          scannedData.Expired = documentReaderReturnedData?.codeline_data?.ExpiredDocumentFlag;
          this.scannedData$.next(scannedData);
          break;
        default:
          break;
      }
    });
  }

  private async start(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        this.logService.debug(`[DocumentReaderDataState]start()`);
        const isConnected = await lastValueFrom(
          this.documentReaderService.isConnected.pipe(take(1))
        );
        if (!isConnected) {
          await this.documentReaderService.connect();
        }
        const hasSession = await lastValueFrom(this.documentReaderService.hasSession.pipe(take(1)));
        if (!hasSession) {
          await this.documentReaderService.startSession();
        }
        const isListening = await lastValueFrom(
          this.documentReaderService.isListening.pipe(take(1))
        );
        if (!isListening) {
          await this.documentReaderService.startListening();
        }

        if (this.settings) {
          await this.documentReaderService.setSettings(this.settings);
        }

        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }

  public async capture(): Promise<void> {
    this.logService.debug(`[DocumentReaderDataState]capture()`);

    const isCapturing = await lastValueFrom(this.documentReaderService.isCapturing.pipe(take(1)));
    if (!isCapturing) {
      this.isConnecting$.next(true);
      this.startTimeoutTimer(10000, this.isConnecting$);
      await this.documentReaderService.startCaptureData();
    }
  }

  public async stop(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        const hasSession = await lastValueFrom(this.documentReaderService.hasSession.pipe(take(1)));
        if (hasSession) {
          await this.documentReaderService.closeSession();
        }
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }
}
