/* eslint-disable no-async-promise-executor */

import { BehaviorSubject, Observable, Subject, combineLatest, lastValueFrom, timer } from 'rxjs';
import { take, takeUntil, takeWhile } from 'rxjs/operators';

import { ApplicationStateService } from '@core/services/application-state.service';
import { DocumentReaderCaptureDataCaptured } from '@data/models/document-reader/document-reader-capture-data-captured.model';
import { DocumentReaderConnectionEvent } from '@data/models/document-reader/document-reader-connection-events.enum';
import { DocumentReaderDataMessage } from '@data/models/document-reader/document-reader-data-message.model';
import { DocumentReaderModule } from '@data/models/document-reader/document-reader-module.model';
import { DocumentReaderReturnedData } from '@data/models/document-reader/document-reader-returned-data.model';
import { DocumentReaderReturnedImage } from '@data/models/document-reader/document-reader-returned-image.model';
import { ErrorMessage } from '@data/models/document-reader/lib/errorMessage';
import { Injectable } from '@angular/core';
import { LogService } from '@core/services/log.service';
import { SessionOpenedMessage } from '@data/models/document-reader/lib/sessionOpenedMessage';
import { SettingsMessage } from '@data/models/document-reader/lib/settingsMessage';
import { WindowRef } from '@core/services/window-ref.service';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class ThalesDocumentReaderApiService {
  private webApiModule: DocumentReaderModule;
  private connectingPromise: Promise<void>;
  private openingSessionPromise: Promise<void>;

  connectionRecorvery$: Observable<number>;
  isReady$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isConnected$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  hasSession$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isListening$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isCapturing$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  onConnected$: Subject<boolean> = new Subject();
  onClosed$: Subject<boolean> = new Subject();
  onInvalidKey$: Subject<boolean> = new Subject();
  onUnProvisionedDevice$: Subject<boolean> = new Subject();
  onSessionClosed$: Subject<boolean> = new Subject();
  onSessionOpened$: Subject<boolean> = new Subject();
  onInvalidSession$: Subject<boolean> = new Subject();
  onSessionInUse$: Subject<SessionOpenedMessage> = new Subject();
  onEvent$: Subject<{ eventId: number }> = new Subject();
  onReturnedImage$: Subject<DocumentReaderReturnedImage> = new Subject();
  onCaptureDataCaptured$: Subject<DocumentReaderCaptureDataCaptured> = new Subject();
  onCapture$: Subject<any> = new Subject();
  onCaptured$: Subject<any> = new Subject();
  onCaptureDataStopped$: Subject<any> = new Subject();
  onCaptureDocFound$: Subject<any> = new Subject();
  onDeviceInfo$: Subject<boolean> = new Subject();
  onReturnedData$: Subject<DocumentReaderReturnedData> = new Subject();
  onListenStarted$: Subject<boolean> = new Subject();
  onListenStopped$: Subject<boolean> = new Subject();
  onError$: Subject<ErrorMessage> = new Subject();
  onInvalidCommand$: Subject<ErrorMessage> = new Subject();
  onSettingsUpdated$: Subject<ErrorMessage> = new Subject();
  onSettingsError$: Subject<ErrorMessage> = new Subject();

  constructor(
    private windowRef: WindowRef,
    public applicationStateService: ApplicationStateService,
    private logService: LogService
  ) {
    this.init();
  }

  get nativeWebapi(): any {
    return this.windowRef.nativeWindow.webapi;
  }
  get isReady(): Observable<boolean> {
    return this.isReady$.asObservable();
  }

  get isConnected(): Observable<boolean> {
    return this.isConnected$.asObservable();
  }

  get hasSession(): Observable<boolean> {
    return this.hasSession$.asObservable();
  }

  get isListening(): Observable<boolean> {
    return this.isListening$.asObservable();
  }

  get isCapturing(): Observable<boolean> {
    return this.isCapturing$.asObservable();
  }

  get onConnected(): Observable<boolean> {
    return this.onConnected$.asObservable();
  }

  get onClosed(): Observable<boolean> {
    return this.onClosed$.asObservable();
  }

  get onUnProvisionedDevice(): Observable<boolean> {
    return this.onUnProvisionedDevice$.asObservable();
  }

  get onInvalidKey(): Observable<boolean> {
    return this.onInvalidKey$.asObservable();
  }

  get onSessionOpened(): Observable<boolean> {
    return this.onSessionOpened$.asObservable();
  }

  get onSessionClosed(): Observable<boolean> {
    return this.onSessionClosed$.asObservable();
  }

  get onInvalidSession(): Observable<boolean> {
    return this.onInvalidSession$.asObservable();
  }

  get onSessionInUse(): Observable<SessionOpenedMessage> {
    return this.onSessionInUse$.asObservable();
  }

  get onEvent(): Observable<{ eventId: number }> {
    return this.onEvent$.asObservable();
  }
  get onReturnedImage(): Observable<DocumentReaderReturnedImage> {
    return this.onReturnedImage$.asObservable();
  }
  get onCaptureDataCaptured(): Observable<DocumentReaderCaptureDataCaptured> {
    return this.onCaptureDataCaptured$.asObservable();
  }
  get onCapture(): Observable<any> {
    return this.onCaptured$.asObservable();
  }
  get onCaptured(): Observable<any> {
    return this.onCaptured$.asObservable();
  }

  get onCaptureDataStopped(): Observable<any> {
    return this.onCaptureDataStopped$.asObservable();
  }

  get onDeviceInfo(): Observable<boolean> {
    return this.onDeviceInfo$.asObservable();
  }
  get onReturnedData(): Observable<DocumentReaderReturnedData> {
    return this.onReturnedData$.asObservable();
  }

  get onListenStarted(): Observable<boolean> {
    return this.onListenStarted$.asObservable();
  }

  get onListenStopped(): Observable<boolean> {
    return this.onListenStopped$.asObservable();
  }

  get onCaptureDocFound(): Observable<boolean> {
    return this.onCaptureDocFound$.asObservable();
  }

  get onError(): Observable<any> {
    return this.onError$.asObservable();
  }

  get onInvalidCommand(): Observable<any> {
    return this.onInvalidCommand$.asObservable();
  }

  public async init() {
    this.logService.debug(`[DocumentReaderService]init()`);

    const deviceConfiguration = await lastValueFrom(
      this.applicationStateService.deviceConfiguration$.pipe(take(1))
    );

    if (!deviceConfiguration) {
      this.logService.error(`[DocumentReaderService]initWebApi - No document scanner ip address`);
      return;
    }
    if (!environment.documentScanner) {
      this.logService.error(`[DocumentReaderService]initWebApi - No document scanner credentials`);
      return;
    }

    await this.initWebApi();

    combineLatest([this.isConnected]).subscribe(([isConnected]) => {
      if (!this.connectionRecorvery$ && !isConnected) {
        this.connectionRecovery();
      }
      if (isConnected) {
        this.isReady$.next(true);
      }
    });
  }

  connectionRecovery() {
    this.logService.debug(`[DocumentReaderService]connectionRecovery`);
    this.connectionRecorvery$ = timer(0, 2000).pipe(
      takeWhile((v, index) => {
        return !this.isConnected$.value;
      })
    );

    this.connectionRecorvery$.subscribe({
      next: async () => {
        await this.connect();
      },
      complete: () => {
        this.connectionRecorvery$ = null;
      },
    });
  }

  public async initWebApi() {
    await this.initWebApiModule();

    this.webApiModule.webapi_enable_logging(`debug`);

    this.webApiModule.on(
      DocumentReaderConnectionEvent[DocumentReaderConnectionEvent.CONNECTED],
      () => {
        this._onConnected();
      }
    );
    this.webApiModule.on(
      DocumentReaderConnectionEvent[DocumentReaderConnectionEvent.CLOSED],
      () => {
        this._onClosed();
      }
    );
    this.webApiModule.on(
      DocumentReaderConnectionEvent[DocumentReaderConnectionEvent.INVALID_KEY],
      () => {
        this._onInvalidKey();
      }
    );
    this.webApiModule.on(
      DocumentReaderConnectionEvent[DocumentReaderConnectionEvent.UNPROVISIONED_DEVICE],
      () => {
        this._onUnProvisionedDevice();
      }
    );
    this.webApiModule.on(
      DocumentReaderConnectionEvent[DocumentReaderConnectionEvent.SESSION_OPENED],
      () => {
        this._onSessionOpened();
      }
    );
    this.webApiModule.on(
      DocumentReaderConnectionEvent[DocumentReaderConnectionEvent.SESSION_CLOSED],
      () => {
        this._onSessionClosed();
      }
    );
    this.webApiModule.on(
      DocumentReaderConnectionEvent[DocumentReaderConnectionEvent.INVALID_SESSION],
      () => {
        this._onInvalidSession();
      }
    );
    this.webApiModule.on(
      DocumentReaderConnectionEvent[DocumentReaderConnectionEvent.SESSION_IN_USE],
      (data) => {
        this._onSessionInUse(data);
      }
    );
    this.webApiModule.on(
      DocumentReaderConnectionEvent[DocumentReaderConnectionEvent.EVENT],
      (data) => {
        this._onEvent(data);
      }
    );
    this.webApiModule.on(
      DocumentReaderConnectionEvent[DocumentReaderConnectionEvent.RETURNED_IMAGE],
      (data) => {
        this._onReturnedImage(data);
      }
    );
    this.webApiModule.on(
      DocumentReaderConnectionEvent[DocumentReaderConnectionEvent.CAPTURE_DATA_CAPTURED],
      (data) => {
        this._onCaptureDataCaptured(data);
      }
    );
    this.webApiModule.on(
      DocumentReaderConnectionEvent[DocumentReaderConnectionEvent.CAPTURE],
      () => {
        this._onCapture();
      }
    );
    this.webApiModule.on(
      DocumentReaderConnectionEvent[DocumentReaderConnectionEvent.CAPTURE_STOPPED],
      () => {
        this._onCaptureDataStopped();
      }
    );
    this.webApiModule.on(
      DocumentReaderConnectionEvent[DocumentReaderConnectionEvent.CAPTURED],
      () => {
        this._onCaptured();
      }
    );
    this.webApiModule.on(
      DocumentReaderConnectionEvent[DocumentReaderConnectionEvent.DEVICE_INFO],
      (data) => {
        this._onDeviceInfo(data);
      }
    );
    this.webApiModule.on(
      DocumentReaderConnectionEvent[DocumentReaderConnectionEvent.RETURNED_DATA],
      (data) => {
        this._onReturnedData(data);
      }
    );
    this.webApiModule.on(
      DocumentReaderConnectionEvent[DocumentReaderConnectionEvent.LISTEN_STARTED],
      (data) => {
        this._onListenStarted();
      }
    );
    this.webApiModule.on(
      DocumentReaderConnectionEvent[DocumentReaderConnectionEvent.LISTEN_STOPPED],
      (data) => {
        this._onListenStopped();
      }
    );
    this.webApiModule.on(
      DocumentReaderConnectionEvent[DocumentReaderConnectionEvent.CAPTURE_DOCFOUND],
      (data) => {
        this._onCaptureDocFound(true);
      }
    );

    this.webApiModule.on(
      DocumentReaderConnectionEvent[DocumentReaderConnectionEvent.ERROR],
      (data) => {
        this._onError(data);
      }
    );

    this.webApiModule.on(
      DocumentReaderConnectionEvent[DocumentReaderConnectionEvent.GET_SETTINGS],
      (data) => {
        this._onGetSettings(data);
      }
    );

    this.webApiModule.on(
      DocumentReaderConnectionEvent[DocumentReaderConnectionEvent.SETTINGS_UPDATED],
      (data) => {
        this._onSettingsUpdated(data);
      }
    );

    this.webApiModule.on(
      DocumentReaderConnectionEvent[DocumentReaderConnectionEvent.SET_SETTINGS_ERROR],
      (data) => {
        this._onSettingsError(data);
      }
    );

    this.webApiModule.on(
      DocumentReaderConnectionEvent[DocumentReaderConnectionEvent.INVALID_COMMAND],
      (data) => {
        this._onInvalidCommand(data);
      }
    );
  }

  private _onConnected() {
    this.logService.debug(`[DocumentReaderService]onConnected()`);
    this.onConnected$.next(true);
    this.isConnected$.next(true);
  }
  private _onClosed() {
    this.logService.debug(`[DocumentReaderService]onClosed()`);
    this.onClosed$.next(true);
    this.isConnected$.next(false);
  }

  private _onInvalidKey() {
    this.logService.debug(`[DocumentReaderService]onInvalidKey()`);
    this.onInvalidKey$.next(true);
    this.isConnected$.next(false);
  }

  private _onUnProvisionedDevice() {
    this.logService.debug(`[DocumentReaderService]onUnProvisionedDevice()`);
    this.onUnProvisionedDevice$.next(false);
    this.isConnected$.next(false);
  }

  private _onSessionOpened() {
    this.logService.debug(`[DocumentReaderService]onSessionOpened()`);
    this.hasSession$.next(true);
    this.onSessionOpened$.next(true);
  }
  private _onSessionClosed() {
    this.logService.debug(`[DocumentReaderService]onSessionClosed()`);
    this.hasSession$.next(false);
    this.isCapturing$.next(false);
    this.isListening$.next(false);
    this.onSessionClosed$.next(true);
  }

  private _onInvalidSession() {
    this.logService.debug(`[DocumentReaderService]onInvalidSession()`);
    this.hasSession$.next(false);
    this.onInvalidSession$.next(true);
  }

  private _onSessionInUse(sessionOpenedMessage: SessionOpenedMessage) {
    this.logService.debug(`[DocumentReaderService]onSessionInUse()`);
    this.hasSession$.next(false);
    this.onSessionInUse$.next(sessionOpenedMessage);
  }

  private _onEvent(data) {
    this.logService.debug(`[DocumentReaderService]onEvent()`, data);
    this.onEvent$.next(data);
  }

  private _onReturnedImage(data: DocumentReaderReturnedImage) {
    this.logService.debug(`[DocumentReaderService]onReturnedImage()`, data);
    this.onReturnedImage$.next(data);
  }

  private _onCaptureDataCaptured(data: DocumentReaderCaptureDataCaptured) {
    this.logService.debug(`[DocumentReaderService]onCaptureDataCaptured()`, data);
    this.onCaptureDataCaptured$.next(data);
  }

  private _onCapture() {
    this.logService.debug(`[DocumentReaderService]onCapture()`);
    this.isCapturing$.next(true);
    this.onCapture$.next(true);
  }

  private _onCaptured() {
    this.logService.debug(`[DocumentReaderService]onCaptured()`);
    this.isCapturing$.next(false);
    this.onCaptured$.next(true);
  }

  private _onCaptureDataStopped() {
    this.logService.debug(`[DocumentReaderService]onCaptureDataStopped()`);
    this.onCaptureDataStopped$.next(true);
  }

  private _onDeviceInfo(data) {
    this.logService.debug(`[DocumentReaderService]onDeviceInfo()`, data);
    this.onDeviceInfo$.next(data);
  }
  private _onReturnedData(data) {
    this.logService.debug(`[DocumentReaderService]onReturnedData()`, data);
    this.onReturnedData$.next(data);
  }

  private _onListenStarted() {
    this.logService.debug(`[DocumentReaderService]onListenStarted()`);
    this.isListening$.next(true);
    this.onListenStarted$.next(true);
  }

  private _onListenStopped() {
    this.logService.debug(`[DocumentReaderService]onListenStop()`);
    this.isListening$.next(false);
    this.onListenStopped$.next(true);
  }

  private _onCaptureDocFound(data) {
    this.logService.debug(`[DocumentReaderService]onCaptureDocFound()`, data);
    this.onCaptureDocFound$.next(data);
  }
  private _onError(data) {
    this.logService.debug(`[DocumentReaderService]onError()`, data);
    this.onCaptureDocFound$.next(data);
  }

  private _onGetSettings(data) {
    this.logService.debug(`[DocumentReaderService]onGetSettings()`, data);
  }

  private _onSettingsUpdated(data) {
    this.logService.debug(`[DocumentReaderService]onSettingsUpdated()`, data);
    this.onSettingsUpdated$.next(data);
  }
  private _onSettingsError(data) {
    this.logService.debug(`[DocumentReaderService]onSettingsError()`, data);
    this.onSettingsError$.next(data);
  }

  private _onInvalidCommand(data) {
    this.logService.debug(`[DocumentReaderService]onInvalidCommand()`, data);
    this.onInvalidCommand$.next(data);
  }

  public async sendMessage(
    documentReaderConnectionEvent: DocumentReaderConnectionEvent,
    data?: any
  ) {
    return this.sendRawMessage(
      new DocumentReaderDataMessage(documentReaderConnectionEvent, await this.getNextId(), data)
    );
  }

  public async sendRawMessage(documentReaderDataMessage: DocumentReaderDataMessage) {
    if (!this.webApiModule) {
      return;
    }
    return this.webApiModule.send_message(JSON.stringify(documentReaderDataMessage));
  }

  public async connect() {
    if (this.connectingPromise) {
      return this.connectingPromise;
    }
    this.connectingPromise = new Promise(async (resolve, reject) => {
      const watcher = new Subject<void>();
      this.logService.debug(`[DocumentReaderService]connect()`);

      const deviceConfiguration = await lastValueFrom(
        this.applicationStateService.deviceConfiguration$.pipe(take(1))
      );

      if (!deviceConfiguration) {
        this.logService.error(`[DocumentReaderService]initWebApi - No document scanner ip address`);
        return;
      }

      if (!environment.documentScanner) {
        this.logService.error(
          `[DocumentReaderService]initWebApi - No document scanner credentials`
        );
        return;
      }

      this.onConnected.pipe(takeUntil(watcher)).subscribe((value) => {
        if (value) {
          this.terminateWatcher(watcher);
          resolve();
          this.connectingPromise = null;
          return;
        }
      });

      this.onInvalidKey.pipe(takeUntil(watcher)).subscribe((value) => {
        if (value) {
          this.terminateWatcher(watcher);
          reject(`invalid-key`);
          this.connectingPromise = null;
          return;
        }
      });

      this.onUnProvisionedDevice.pipe(takeUntil(watcher)).subscribe((value) => {
        if (value) {
          this.terminateWatcher(watcher);
          reject(`unprovisioned-device`);
          this.connectingPromise = null;
          return;
        }
      });

      this.onInvalidCommand$.pipe(takeUntil(watcher)).subscribe((value) => {
        if (value) {
          this.terminateWatcher(watcher);
          reject(`invalid-command`);
          this.connectingPromise = null;
          return;
        }
      });

      this.onClosed$.pipe(takeUntil(watcher)).subscribe((value) => {
        if (value) {
          this.terminateWatcher(watcher);
          this.connectingPromise = null;
          reject(`closed-connection`);
          return;
        }
      });

      this.webApiModule.open_connection(
        deviceConfiguration.passportScannerIp,
        environment.documentScanner.apidId,
        environment.documentScanner.apiKey
      );
    });
    return this.connectingPromise;
  }

  terminateWatcher(watcher: Subject<void>) {
    watcher.next();
    watcher.complete();
  }

  public async startSession() {
    if (this.openingSessionPromise) {
      return this.openingSessionPromise;
    }
    this.openingSessionPromise = new Promise(async (resolve, reject) => {
      const watcher = new Subject<void>();
      this.logService.debug(`[DocumentReaderService]startSession()`);

      this.onSessionOpened.pipe(takeUntil(watcher)).subscribe((onSessionOpened) => {
        if (onSessionOpened) {
          this.terminateWatcher(watcher);
          this.openingSessionPromise = undefined;
          return resolve();
        }
      });

      this.onSessionInUse.pipe(takeUntil(watcher)).subscribe((onSessionInUse) => {
        if (onSessionInUse) {
          this.terminateWatcher(watcher);
          this.openingSessionPromise = undefined;
          return reject(`session-in-use`);
        }
      });

      this.onInvalidCommand$.pipe(takeUntil(watcher)).subscribe((value) => {
        if (value) {
          this.terminateWatcher(watcher);
          this.openingSessionPromise = undefined;
          reject(`invalid-command`);
        }
      });

      await this.sendMessage(DocumentReaderConnectionEvent.SESSION_OPEN);
    });
    return this.openingSessionPromise;
  }

  public async closeSession() {
    return new Promise((resolve, reject) => {
      const watcher = new Subject<void>();
      this.logService.debug(`[DocumentReaderService]closeSession()`);

      this.onSessionClosed.pipe(takeUntil(watcher)).subscribe((value) => {
        this.terminateWatcher(watcher);
        resolve(true);
      });

      this.onInvalidCommand$.pipe(takeUntil(watcher)).subscribe((value) => {
        if (value) {
          this.terminateWatcher(watcher);
          reject(`invalid-command`);
        }
      });

      this.sendMessage(DocumentReaderConnectionEvent.SESSION_CLOSE);
    });
  }

  public async startListening() {
    return new Promise((resolve, reject) => {
      const watcher = new Subject<void>();
      this.logService.debug(`[DocumentReaderService]startListening()`);

      this.onListenStarted.pipe(takeUntil(watcher)).subscribe((value) => {
        this.terminateWatcher(watcher);
        resolve(true);
      });

      this.onInvalidCommand$.pipe(takeUntil(watcher)).subscribe((value) => {
        if (value) {
          this.terminateWatcher(watcher);
          reject(`invalid-command`);
        }
      });

      this.sendMessage(DocumentReaderConnectionEvent.LISTEN_START);
    });
  }

  public async stopListening() {
    return new Promise((resolve, reject) => {
      const watcher = new Subject<void>();
      this.logService.debug(`[DocumentReaderService]stopListening()`);

      this.onListenStopped.pipe(takeUntil(watcher)).subscribe((value) => {
        this.terminateWatcher(watcher);
        resolve(true);
      });

      this.onInvalidCommand$.pipe(takeUntil(watcher)).subscribe((value) => {
        if (value) {
          this.terminateWatcher(watcher);
          reject(`invalid-command`);
        }
      });

      this.sendMessage(DocumentReaderConnectionEvent.LISTEN_STOP);
    });
  }

  public async startCaptureData() {
    return new Promise((resolve, reject) => {
      const watcher = new Subject<void>();

      this.logService.debug(`[DocumentReaderService]startCaptureData()`);

      this.onInvalidCommand$.pipe(takeUntil(watcher)).subscribe((value) => {
        if (value) {
          this.terminateWatcher(watcher);
          reject(`invalid-command`);
        }
      });

      this.sendMessage(DocumentReaderConnectionEvent.CAPTURE_DATA);
      resolve(true);
    });
  }

  public async stopCaptureData() {
    return new Promise((resolve, reject) => {
      const watcher = new Subject<void>();
      this.logService.debug(`[DocumentReaderService]stopCaptureData()`);

      this.onCaptureDataStopped.pipe(takeUntil(watcher)).subscribe((value) => {
        this.terminateWatcher(watcher);
        resolve(true);
      });

      this.onInvalidCommand$.pipe(takeUntil(watcher)).subscribe((value) => {
        if (value) {
          this.terminateWatcher(watcher);
          reject(`invalid-command`);
        }
      });

      this.sendMessage(DocumentReaderConnectionEvent.CAPTURE_STOP);
    });
  }

  public async setSettings(settings: any) {
    return new Promise((resolve, reject) => {
      const watcher = new Subject<void>();
      this.logService.debug(`[DocumentReaderService]setSettings()`);

      this.onSettingsUpdated$.pipe(takeUntil(watcher)).subscribe((value) => {
        this.terminateWatcher(watcher);
        resolve(true);
      });

      this.onSettingsError$.pipe(takeUntil(watcher)).subscribe((value) => {
        if (value) {
          this.terminateWatcher(watcher);
          reject(`invalid-command`);
        }
      });
      this.sendMessage(DocumentReaderConnectionEvent.SET_SETTINGS, SettingsMessage.copy(settings));
    });
  }

  public async closeConnection() {
    if (!this.webApiModule) {
      return;
    }
    this.webApiModule.close_connection();
  }

  public async getNextId(): Promise<number> {
    if (!this.webApiModule) {
      return;
    }
    return this.webApiModule.getNextI();
  }

  public async on(event: DocumentReaderConnectionEvent, handler: () => any) {
    this.webApiModule.on(event, handler);
  }

  private async initWebApiModule(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!this.nativeWebapi) {
        return reject(new Error(`nativeWebapi cant be found`));
      }
      this.webApiModule = new DocumentReaderModule();
      this.nativeWebapi(this.webApiModule).then((webApiModule) => {
        return resolve();
      });
    });
  }
}
